<script setup lang="ts">

import {ApiService} from "@/api/apiService";
import {formatNumber} from "@/tools/tools";
import Player from "@/models/player";
import Game from "@/models/game";
import CountTimer from "@/components/game/CountTimer.vue";
import {defineProps, watch, ref, inject} from "vue";
import store from "@/store";
import EmojiPlayerItem from "./EmojiPlayerItem.vue";
import soundNewAudio from '@/assets/sounds/new_audio.mp3';

const playSound = inject<() => void>("playSound");

type Seat = {
  player: Player | null,
  seat: number
}

const props = defineProps<{
  seat: Seat,
  timer: number,
  game: Game,
  dealerSeat: number | null,
  isSecondLine: boolean,
  playerCards: any,
  currentCase: string | null;
  selectedEmoji: any,
  selectedEmojiPlayerID: number | undefined
  winners: any[]
}>()

const transformStyle = ref('');
const transformStyleCard = ref('');
const userBetText = ref(formatNumber(props.seat.player?.game_deposit ?? 0));

watch(
  () => props.currentCase,
  (newCase) => {
    const isCurrentPlayerWinner = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.seat.player?.user_id);

    if (newCase === 'show_down' && isCurrentPlayerWinner) {

      transformStyle.value = 'transform: scale(1.15) translateY(10px); z-index: 4;';
      transformStyleCard.value = 'filter: drop-shadow(0px 0px 3px rgba(240, 190, 121));';
      userBetText.value = 'win';
    } else if (newCase !== 'show_down') {
      transformStyle.value = '';
      userBetText.value = formatNumber(props.seat.player?.game_deposit ?? 0);
    }
  }
);

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

watch(() => props.currentCase, (newCase) => {
  
  if (newCase === 'show_down') {
    updatePlayerCards();
  }
});

const userCard1Ref = ref<HTMLImageElement | null>(null);
const userCard2Ref = ref<HTMLImageElement | null>(null);
const userCardRef = ref<HTMLImageElement | null>(null);

async function updatePlayerCards(playerCards = props.playerCards) {

  if (!playerCards || playerCards.length < 2) {
    return;
  }

  playerCards.forEach(async playerCard => {
    const playerId = Object.keys(playerCard)[0]; 
    

    if (playerId == props.seat.player?.id.toString()) {
      const cards = Object.values(playerCard)[0] as { rank: string, suit: string }[];
      
      if (cards.length == 2) {
        const card1 = cards[0];
        const card2 = cards[1];

        try {

          const image1El = document.getElementById('user-card-11');
          const image2El = document.getElementById('user-card-22');

          const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card1.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card1.suit.toLowerCase(): card1.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);
          const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card2.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card2.suit.toLowerCase(): card2.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);

          await preloadImage(image1);
          await preloadImage(image2);

          if (userCard1Ref.value && userCard2Ref.value && userCardRef.value) {
            let showdownSound = new Audio(soundNewAudio);
            showdownSound.play();
            
            userCard1Ref.value.src = image1;
            userCard2Ref.value.src = image2;
            
            userCardRef.value.classList.add('showdown-div');
            userCard1Ref.value.classList.add('showdown-card');
            userCard2Ref.value.classList.add('showdown-card', 'showdown-card-delay');
            if (playSound) {
              playSound(); 
            }  
            
          } 
        } catch (error) {
          console.error('Ошибка загрузки изображений карт:', error);
        }
      }
    }
  });
}


</script>



<template>
  <div v-if="seat.player" :class="{ 'player': true, 'active-player': seat.player.id == game?.current_player_id, 'not-active-player': seat.player.id != game?.current_player_id, 'not-watcher':seat.player.status == 'active'}" :style="transformStyle" >
    <div class="user-image-count">
      <count-timer :timer="timer" :game="game" :player="seat.player"/>
      <div class="dealer-button" v-if="seat.player.seat === dealerSeat">
        <img class="dealer-button-button" :src="require(`@/assets/dealer-buttons/btn-dealer-${store.getters.USER.vip_status}.svg`)">
      </div>
      <div class="emoji-item-player">
        <emoji-player-item 
        :selected-emoji="selectedEmoji"
        :selectedEmojiPlayerID="selectedEmojiPlayerID!"
        :seat="seat"
        />
      </div>
      <img :class="{ 'user-game-logo': true, 'no-image-animation': true, 'user-game-logo__inactive': seat.player.status != 'active' }" :src="seat.player.user_photo_url ? ApiService.url + seat.player.user_photo_url : require('@/assets/mock_user/user.svg')">
    </div>
    <div class="user-bet-username no-image-animation">
      <img class="user-card-1 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" >
      <img class="user-card-2 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" >
      <p class="user-bet no-image-animation">{{ userBetText }}</p>
      <p class="user-username no-image-animation">{{ seat.player.user_username ?? '---' }}</p>
    </div>
    <div class="showdown" ref="userCardRef">
      <img class="user-card-11" id="user-card-11" ref="userCard1Ref" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" :style="transformStyleCard">
      <img class="user-card-22" id="user-card-22" ref="userCard2Ref" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" :style="transformStyleCard">
    </div>
    
  </div>
  <div v-else>
    <img class="user-game-logo no-image-animation" :src="require('@/assets/mock_user/no-user-game.png')">
    <div class="user-bet-username no-image-animation">
      <div style="height: 3.5vh"></div>
      <p class="user-bet no-image-animation">---</p>
      <p class="user-username no-image-animation">---</p>
    </div>
  </div>
  
  
</template>

<style scoped>

.winner {
  position: absolute;
  font-weight: 500;
  font-size: 1.3vh;
}

.showdown {
  position: absolute;
  left: 27%;
  bottom: 10px;
  transform: scale(2);
  opacity: 0;
  z-index: 7;
}

.emoji-item-player{
  position: absolute;
  top: 36%;
  left: 85%;
  z-index: 9;
}


.user-card-11 {
  position: absolute;
  height: 30px;
  top: -13px;
  rotate: 19deg;
  z-index: 9;
  right: -25px;
  opacity: 0;
  box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.6);
}

.user-card-22 { 
  position: absolute;
  height: 30px;
  top: -13px;
  rotate: 353deg;
  margin-left: -10px;
  opacity: 0;
  box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.6);
  z-index: 8;
}

.dealer-button {
  position: absolute;
  max-width: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
  z-index: 5;
  top: -7px;
  right: 0;
}


.dealer-button-button {
  max-width: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
} 

.user-game-logo {
  height: 6vh;
  border-radius: 50%;
}
.user-bet-username {
  margin-top: -10px;
}

.user-username {
  font-weight: 500;
  font-size: 1.1vh;
}

.user-bet {
  margin-top: -25px;
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 1.2vh;
  background-color: #5C4E73;
  position: relative;
  border-radius: var(--border-mixed);
  z-index: 10;
}

.user-card-1 {
  position: relative;
  height: 2.7vh;
  margin-left: -35%;
  top: -13px;
  rotate: -48.55deg;
  z-index: 5;
}

.user-card-2 {
  position: relative;
  height: 2.7vh;
  top: -16px;
  left: 5px;
  rotate: -22.42deg;
  margin-left: -10px;
}

.player {
  position: relative;
  transition: 0.3s;
  z-index: 3;
}

.active-player {
  transform: scale(1.2, 1.2) translateY(-6px);
  position: relative;
}

.not-active-player {
  transform: scale(1);
}
.user-image-count {
  position: relative;
}

.user-game-logo__inactive {
  filter: brightness(70%);
}

@keyframes fadeIn {
  0% {
    transform: scale(1.2, 1.2) translateY(-6px);
  }
  100% {
    transform: scale(1);
  }
}

.showdown-card {
  position: absolute;
  animation: showdowncard 0.45s ease forwards;
}

.showdown-div {
  position: absolute;
  animation: showdown 0.45s ease forwards;
}

.showdown-card-delay {
  animation-delay: 0.1s;
}

@keyframes showdowncard {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showdown {
  from {
    opacity: 0;
  }
  to {
    bottom: 55px;
    opacity: 1;
  }
}

</style>