<script setup lang="ts">
import router from "@/router";
import {ApiService} from "@/api/apiService";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {computed, onMounted, ref} from "vue";
import User from "@/models/user";
import store from "@/store";
import {formatNumber} from "../../tools/tools";
import {formatNumberByZiro} from "../../tools/tools";
// import axios from 'axios';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userData = retrieveLaunchParams().initData!;
const user: User = store.getters.USER;

const weekEarnLabel = computed(() => {
  return store.getters.USER.week_earn > 0 ? 'week-earn-label-green' : 'week-earn-label-red'
});

const getUserBackground = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "white";
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)";
    default:
      return "white";
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
    <header class="logo">
      <img src="@/assets/logo/logo.svg" class="no-image-animation">
    </header>
    <section class="first_section">
      <div class="rooms" @click="triggerHapticFeedback('light'); router.push('/rooms')">
        <p class="rooms-label">{{ t(`main.rooms`) }}</p>
        <div style="white-space: nowrap">
          <img class="user-main-card-1 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
          <img class="user-main-card-2 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
        </div>
      </div>
      <div class="balance-wrapper" @click="triggerHapticFeedback('light'); router.push('/profile')">
        <div class="balance">
          <div class="first-balance">
            <div class="springy-animation">
              <p class="balance-label">{{ t(`main.balance`) }}</p>
              <div class="balance-sum-wrapper">
                <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.balance) }}</p>
                <div class="balance-sum-logo-wrapper">
                  <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
                </div>
              </div>
            </div>
            <div>
              <div class="balance-topup-main"  @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
                <p class="balance-topup-main-plus">+</p>
              </div>
            </div>
          </div>
          <div class="second-balance">
            <div class="mini-user-info">
              <img class="mini-user-status" v-if="store.getters.USER.vip_status != 'first'" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" alt="">
              <div class="mini-user-image-wrapper">
                <img class="mini-user-image" :src="user.photo_url ? ApiService.url + user.photo_url : require('@/assets/mock_user/user.svg')">
                <!-- <img class="mini-user-image" :src="userProfilePhotoUrl || (user.photo_url ? ApiService.url + user.photo_url : require('@/assets/mock_user/user.svg'))"> -->
              </div>
            </div>
            <div class="week-earn">
              <div>
                <p class="user-info-username">{{ userData.user!.username ?? "noname" }}</p>
              </div>
              <div class="user-info-week-earn" v-if="store.getters.USER.week_earn">
                <p class="week-earn-label">{{ t(`main.in`) }}&nbsp;{{ t(`main.a_week`) }}:&nbsp;</p>
                <p :class="weekEarnLabel">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
                <div>
                  <img class="balance-sum-logo-wrapper-mini" src="@/assets/logo/money_logo.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
    </section>
</template>

<style scoped>
.logo {
  margin-top: 3%;
  margin-bottom: 3%;
}

.first_section {
  display: flex;
  justify-content: space-between;
}

.rooms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #15151E;
  text-align: left;
  width: 39%;
  border-radius: var(--border-mixed);
  margin-right: 20px;
  overflow: hidden;
}

.rooms-label {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
}

.balance-wrapper {
  overflow: hidden;
  position: relative;
  width: 70%;
  align-items: center;
  background-color: #15151E;
  border-radius: var(--border-mixed);
}

.balance {
  margin: 26px 15px 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.balance-label {
  font-size: 13px;
  font-weight: 600;
  color: #B9B9B9;
}

.balance-sum {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
}


.balance-sum-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.balance-sum-wrapper {
  display: flex;
}

.first-balance {
  display: flex;
  justify-content: space-between;
}

.balance-topup-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #A5A5A6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.balance-topup-main-plus {
  text-align: center;
  color: #A5A5A6;
}

.mini-user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.mini-user-info {
  position: relative;
}

.week-earn {
  margin-left: 11px;
}

.user-info-username {
  font-weight: 500;
  font-size: 13px;
  color: #787878;
}

.second-balance {
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.week-earn-label {
  font-size: 3vw;
  font-weight: 500;
  margin-top: 9px;
  color: #B9B9B9;
}

.week-earn-label-green{
  font-size: 3vw;
  font-weight: 500;
  margin-top: 9px;
  color: #31B545;
}

.week-earn-label-red{
  font-size: 3vw;
  font-weight: 500;
  margin-top: 9px;
  color: red;
}

.balance-sum-logo-wrapper-mini {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 2px;
  width: 15px;
  height: 15px;
  margin-top: 12px;
}

.week-earn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.user-main-card-1 {
  z-index: 2;
  position: relative;
  width: 45%;
  margin-top: 25px;
  transform: rotate(-13.72deg);
  margin-left: 10%;
}

.user-main-card-2 {
  z-index: 1;
  backdrop-filter: blur(20px);
  position: relative;
  width: 56%;
  filter: blur(3px);
  transform: rotate(15.87deg);
  margin-top: 25px;
  top: -10px;
  right: 13px;
}

.background-logo {
  position: absolute;
  text-align: right;
  z-index: 1000;
  overflow: hidden;
  top: 0;
  right: 5px;
  z-index: 0;
}

.user-info-week-earn {
  display: flex;
  align-items: center;
}

.mini-user-image-wrapper {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: v-bind('getUserBackground');
}

.mini-user-status {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: 10px;
  top: -16.5px;
}
</style>