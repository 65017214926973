<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps({
  label: String,
  image: String,
  path: Array
});
</script>

<template>
  <div :class="{ 'nav-bar-item': true, 'nav-bar-item__active': path?.includes($route.fullPath) === true }">
    <div class="nav-bar-item__image-wrapper">
      <img :src="require(`@/assets/navigation/${image}`)" :alt="label" class="nav-bar-item__image">
    </div>
    <div class="nav-bar-item__label-wrapper">
      <p class="nav-bar-item__label">{{ t(`menu.${label}`) }}</p>
    </div>
  </div>
</template>

<style scoped>

.nav-bar-item {
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 5.59vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 9px 5px;
}

.nav-bar-item__active {
  background-color: #4A3E5D;
}

.nav-bar-item__image {
  height: 6.04vw;
}

.nav-bar-item__label {
  font-weight: 500;
  font-size: 2.5vmin;
}
</style>