<template>
    <div class="emoji-player">
        <div @click="selectEmoji('positive', $event)" ref="emojiLike" class="emoji-like"></div>
        <div @click="selectEmoji('negative', $event)" ref="emojiDislike" class="emoji-dizlike"></div>
        <div @click="selectEmoji('neutral', $event)" ref="emojiAgr" class="emoji-agr"></div>
        <div @click="selectEmoji('closing', $event)" ref="emojiHmm" class="emoji-hmm"></div>
        <div class="chat-bl" @click="emitChatClick">
            <div v-if="hasUnreadMessages" class="bubble"></div>
            <img src="@/assets/game/emoji/chat.svg" class="chat">
        </div>
        <EmojiBar
        v-if="selectedCategory"
        :category="selectedCategory"
        :position="emojiBarPosition"
        @emojiSelected="addSelectedEmoji"
        @closeEmojiBar="closeEmojiBar"
        />
    </div>
</template>
    
<script setup lang="ts">

import { onMounted, onUnmounted, ref, defineEmits, defineProps, watch } from 'vue';
import lottie from 'lottie-web';
import EmojiBar from '@/components/game/EmojiBar.vue';
import { RoomService } from '@/api/roomService'; 
import Room from '@/models/room';

import likeAnimationData from '@/assets/game/emoji/Positive/Like.json';
import disAnimationData from '@/assets/game/emoji/Negative/Thumb_down.json';
import agrAnimationData from '@/assets/game/emoji/Neutral/Ok.json';
import hmmAnimationData from '@/assets/game/emoji/Closing/Bye.json';
import store from "@/store";

const props = defineProps<{
  room:Room,
  showPlayer:boolean,
  messageData:any
}>()


const roomService = new RoomService();

const currentUser = store.getters.USER.id 
const hasUnreadMessages = ref<boolean>(); 


const emit = defineEmits(['chatClicked']);

const addSelectedEmoji = (emoji: any) => {
    sendEmj(emoji.nm.toString());
};

function sendEmj(emoji:string){
    roomService.sendMessage(props.room.id, emoji!, "emoji");
}

const emitChatClick = () => {
    if (!props.showPlayer) return;
    emit('chatClicked');
    hasUnreadMessages.value = false; 
};

const closeEmojiBar = () => {
    selectedCategory.value = null; 
};

const selectedCategory = ref<string | null>(null);
const emojiBarPosition = ref({ top: 0, left: 0 });

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function selectEmoji(category: string, event: MouseEvent) {
    if (!props.showPlayer) return;
    triggerHapticFeedback('soft');
    if (selectedCategory.value === category) {
    selectedCategory.value = null;
    return;
    }

    selectedCategory.value = category;

    const targetElement = event.currentTarget as HTMLElement;

    const { top, left, height } = targetElement.getBoundingClientRect();
    const parentElement = targetElement.offsetParent as HTMLElement;

    emojiBarPosition.value = {
    top: top - parentElement.getBoundingClientRect().top + height - 90, 
    left: left - parentElement.getBoundingClientRect().left, 
    };
}

const emojiLike = ref(null);
const emojiDislike = ref(null);
const emojiAgr = ref(null);
const emojiHmm = ref(null);

let likeAnimationInstance: any = null;
let disAnimationInstance: any = null;
let agrAnimationInstance: any = null;
let hmmAnimationInstance: any = null;

onMounted(() => {
    if (emojiLike.value) {
        likeAnimationInstance = lottie.loadAnimation({
        container: emojiLike.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: likeAnimationData,
        });
    }

    if (emojiDislike.value) {
        disAnimationInstance = lottie.loadAnimation({
        container: emojiDislike.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: disAnimationData,
        });
    }

    if (emojiAgr.value) {
        agrAnimationInstance = lottie.loadAnimation({
        container: emojiAgr.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: agrAnimationData,
        });
    }

    if (emojiHmm.value) {
        hmmAnimationInstance = lottie.loadAnimation({
        container: emojiHmm.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: hmmAnimationData,
        });
    }
});

onUnmounted(() => {
if (likeAnimationInstance) {
    likeAnimationInstance.destroy();
}
if (disAnimationInstance) {
    disAnimationInstance.destroy();
}
if (agrAnimationInstance) {
    agrAnimationInstance.destroy();
}
if (hmmAnimationInstance) {
    hmmAnimationInstance.destroy();
}
});

watch(() => props.messageData, (newMessage) => {
    if (props.messageData.type ==='emoji') return;
    if (newMessage && newMessage.user_id !== currentUser) {
        hasUnreadMessages.value = true;
    }
}, { immediate: true, deep: true });


</script>
    
<style scoped>
.emoji-player {
    display: flex;
    gap: 11px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 22px;
    padding-right: 17px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #00000030;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
}
.emoji-like, .emoji-dizlike, .emoji-agr, .emoji-hmm, .cha, .chat-bl {
    width: 8.14vw;
    height: 8.14vw;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    &:active, &:focus {
        background-color: transparent;
    }
}

.chat-bl {
    position: relative;
}

.bubble{
    position: absolute;
    background-color: #E53935;
    width: 3vw;
    height: 3vw;
    border-radius: 100%;
    right: -4px;
    top: -3px;
}
</style>