<script setup lang="ts">

import store from "@/store";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted} from "vue";

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
  <div class="user-agreement-wrapper" v-if="store.getters.OPEN_MODAL === 'user-agreement'">
    <div class="user-agreement-modal">
        <div class="header">
            <div class="header-text">
                User Agreement
            </div>
            <div class="close-modal-wrapper">
              <img src="@/assets/tools/close-modal.svg" @click="closeModal">
            </div>
        </div>
        <div class="text">
        <br><br>
        <small>last upd: 2024-08-28</small>
        <br><br>
        <h3>1. Introduction</h3>
        <br><br>
        Welcome to OkGo Poker! This User Agreement ("Agreement") governs your use of our online poker room services. By registering, accessing, or using our services, you agree to be bound by the terms and conditions set forth in this Agreement.
        <br><br>
        <h3>1. Eligibility</h3>
        <br><br>
        You must be at least 18 years old or the legal age of majority in your jurisdiction to use our services. It is your responsibility to ensure that you are legally allowed to play online poker in your jurisdiction. You acknowledge that you use OkGo Poker (you are located) in a jurisdiction where it is considered as a lawful entertainment. By joining the Agreement, you also acknowledge that your access to OkGo Poker may be restricted due to legal restrictions applicable in the place of your presence.
        <br><br>
        <h3>2. Digital Assets Only</h3>
        <br><br>
        OkGo Poker does not operate with any type of fiat money. Users can play exclusively with digital assets. OkGo Poker does not offer services for buying or selling these digital assets via the poker room.
        <br><br>
        <h3>3. Account Registration</h3>
        <br><br>
        a. You must provide accurate and complete information during the registration process.
        <br><br>
        b. You are responsible for the security of your account and any actions taken under your account.
        <br><br>
        c. Multiple accounts are prohibited. Players found to be using multiple accounts will have all accounts suspended.
        <br><br>
        <h3>4. Fair Play</h3>
        <br><br>
        a. Use of third-party software, bots, or any cheating methods is strictly prohibited.
        <br><br>
        b. Collusion with other players or any form of cheating will result in account suspension and forfeiture of digital assets.
        <br><br>
        c. We reserve the right to monitor and review gameplay to ensure fairness.
        <br><br>
        <h3>5. Rake Policy</h3>
        <br><br>
        A rake of 5% will be taken from each pot in cash games. The rake is used to support the operation and maintenance of OkGo Poker.
        <br><br>
        <!-- <h3>6. Deposits and Withdrawals</h3>
        <br><br>
        a. You may deposit digital assets into your account using the methods provided on our platform. A deposit fee of 5% will be charged, which is used to support the operation and maintenance of OkGo Poker.
        <br><br>
        b. Withdrawals will be processed exclusively in the form of digital assets. A withdrawal fee will be charged based on your level:
        <br>
        <br>
            5% for the first level,
            <br>
            4% for the second level,
            <br>
            3% for the third level,
            <br>
            2% for the fourth level.
        <br>
        <br>
        c. We reserve the right to request verification documents before processing any withdrawal request.
        <br><br> -->
        <h3>6. Bonuses and Promotions</h3>
        <br><br>
        a. We may offer bonuses and promotions from time to time. Each promotion will have its terms and conditions.
        <br><br>
        b. Abuse of bonuses or promotions will result in the forfeiture of bonus funds and potential account suspension.
        <br><br>
        <h3>7. Limitation of Liability</h3>
        <br><br>
        OkGo Poker shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.
        <br><br>
        <h3>8. Dispute Resolution</h3>
        <br><br>
        Any disputes arising out of this Agreement shall be resolved through mediation. If mediation fails, disputes will be settled at the place of location of OkGo Poker.
        <br><br>
        <h3>9. Termination</h3>
        <br><br>
        You agree that we, in our sole discretion and for any or no reason, may terminate the Agreement and suspend and/or terminate your account(s) for the OkGo Poker. You agree that any suspension or termination of your access to OkGo Poker may be without prior notice, and that we will not be liable to you or to any third party for any such suspension or termination. If we terminate the Agreement or suspend or terminate your access to or use of OkGo Poker due to your breach of the Agreement or any suspected fraudulent, abusive, or illegal activity, then termination of the Agreement will be in addition to any other remedies we may have at law or in equity. Upon any termination or expiration of the Agreement, whether by you or us, you may no longer have access to information that you have posted on OkGo Pokeror that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or to any third party.
        <br><br>
        <h3>10. Changes to Agreement</h3>
        <br><br>
        We may update this Agreement from time to time. Any changes will be effective immediately upon posting. Your continued use of our services constitutes acceptance of any changes.
        <br><br>
        <h3>11. Governing Law</h3>
        <br><br>
        This Agreement is governed by the laws of OkGo Poker’s jurisdiction.
        <br><br>
        <h3>12. Contact</h3>
        <br><br>
        For any questions or concerns regarding this Agreement, please contact us at @okgopokerhelpbot
        <br><br>
        <!-- <h3>14. Game Levels</h3>
        <br><br>
        Levels in the game differ in visual design and the withdrawal fee. You can find more details about each level in the "Profile" section by clicking on your avatar.
        <br><br>
        <h3>15. Level Achievement:</h3>
        a. The first level is achieved with a deposit of 1 TON,
        <br>
        <br>
        b. The second level is achieved with a deposit of 10 TON,
        <br>
        <br>
        c. The third level is achieved with a deposit of 50 TON,
        <br>
        <br>
        d. The fourth level is achieved with a deposit of 200 TON. -->
        </div>
    </div>
  </div>
</template>

<style scoped>

.user-agreement-wrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  top: -45px;
  background-color: rgba(0,0,0,0.7);
}

.text {
  max-width: 430px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  font-size: 14px;
  background-color: #30313E;
  margin-top: 62px;
  text-align: start;
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.header {
  position: fixed;
  max-width: 430px;
  width: 100%;
  background-color: #292936;
  border-top-left-radius: 4.65vw;
  border-top-right-radius: 4.65vw;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  z-index: 1;
  font-size: 18px;
}

.header-text {
    margin-left: 20px;
}

h3 {
    font-size: 18px;
}

.user-agreement-modal {
  background-color: #292936;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  top: 106px;
  position: relative;
}

.close-modal-wrapper {
  display: flex;
  justify-content: right;
  margin-right: 20px;
}
</style>