type MenuItems = {
    label: string,
    image: string,
    path: string[],
    hide_small_devices?: boolean
}

const menu: MenuItems[] = [
    {
        label: "play",  
        image: "play.svg",
        path: ['/'],
    },
    {
        label: "rooms", 
        image: "rooms.svg",
        path: ['/rooms']
    },
    {
        label: "friends", 
        image: "friends.svg",
        path: ["/friends"]
    },
    {
        label: "profile", 
        image: "profile.svg",
        path: ['/profile', '/profile/transactions', '/profile/top-up', '/profile/withdraw'],
    },
    {
        label: "settings", 
        image: "settings.svg",
        path: ['/settings']
    }
];

export default menu;
