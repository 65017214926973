<script setup lang="ts">

import store from "@/store";
import router from "@/router";
import {onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  store.commit('SET_OPEN_MODAL', "");
}

// const topUp = () => {
//   router.push('/profile/top-up');
//   triggerHapticFeedback('light');
//   closeModal();
// }

const inVite = () => {
  router.push('/friends');
  triggerHapticFeedback('light');
  closeModal();
}

onUnmounted(() => {
  triggerHapticFeedback('light');
  closeModal()
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="delete-modal-wrapper" v-if="store.getters.OPEN_MODAL === 'balance-top-up'">
    <div class="delete-modal">
      <div class="close-modal-wrapper">
        <img src="@/assets/tools/close-modal.svg" @click="closeModal">
      </div>
      <p class="warning-main">{{ t(`top_up_modal.warning_main`) }}</p>
      <p class="warning-full">{{ t(`top_up_modal.warning_full`) }}</p>
      <div class="cancel-button" @click="inVite">{{ t(`top_up_modal.cancel_button`) }}</div>
    </div>
  </div>
</template>

<style scoped>
.delete-modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0,0,0,0.7);
}

.delete-modal {
  background-color: #292936;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  padding: 12px 12px 57px;
}

.warning-main {
  font-weight: 500;
  font-size: 18px;
}

.warning-full {
  font-weight: 500;
  font-size: 14px;
  margin-top: 25px;
}

.delete-button {
  background: #FD3838;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
}

.cancel-button {
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  width: 100%;
  margin-top: 14px;
}

.close-modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>