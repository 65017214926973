import {ApiService} from "@/api/apiService";
import store from "@/store";

export class RoomService {
    public async rooms(limit: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms?limit=${limit}`, { headers: headers })
    }

    public async room(small_blind_bet: number, seat: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms/${small_blind_bet}?seat=${seat}`, { headers: headers })
    }

    public async joinRoom(roomId: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/joinRoom`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async leaveRoom(roomId: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/leaveRoom`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                })
            })
    }

    public async getPlayerCards(): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/game/playerCards`, { headers: headers })
    }

    public async check(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/check`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async call(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/call`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async fold(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/fold`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async bet(bet: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/bet`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    bet: bet
                })
            })
    }

    public async raise(bet: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/raise`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    bet: bet
                })
            })
    }

    public async messages(roomId: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/messages`, { headers: headers })
    }

    public async sendMessage(roomId: number, message: string, type: string): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/messages`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    body: message,
                    type: type
                })
            })
    }

    public async setDeposit(amount:number, user_id:number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/top-up`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    amount: amount,
                    user_id: user_id
                })
            })
    }
}