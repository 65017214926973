import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from '../views/ProfileView.vue'
import RoomsView from '../views/RoomsView.vue'
import ConnectWalletView from "../views/ConnectWalletView.vue";
import BalanceActionsView from '../views/BalanceActionsView.vue';
import SettingsView from '@/views/SettingsView.vue';
import TransactionHistoryView from "@/views/TransactionHistoryView.vue";
import SettingLanguageView from "@/views/SettingLanguageView.vue";
import TransactionView from "@/views/TransactionView.vue";
import GameView from "@/views/GameView.vue";
import FriendsView from "@/views/FriendsView.vue";
import LevelsView from "@/views/LevelsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: RoomsView,
    props: true,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/top-up',
    name: 'top-up',
    component: BalanceActionsView,
    props: {
      action: "top-up"
    },
  },
  {
    path: '/profile/withdraw',
    name: 'withdraw',
    component: BalanceActionsView,
    props: {
      action: "withdraw"
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/settings/language',
    name: 'language',
    component: SettingLanguageView
  },
  {
    path: '/profile/connect-wallet',
    name: 'connect-wallet',
    component: ConnectWalletView
  },
  {
    path: '/profile/transactions',
    name: 'transactions',
    component: TransactionHistoryView
  },
  {
    path: '/profile/transaction',
    name: 'transaction',
    component: TransactionView,
    props: true
  },
  {
    path: '/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/friends',
    name: 'friends',
    component: FriendsView
  },
  {
    path: '/levels',
    name: 'levels',
    component: LevelsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
