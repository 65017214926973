<script setup lang="ts">
import { formatNumber } from "@/tools/tools";
import RoomsListItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps<{
  room: RoomsListItem
}>();

</script>

<template>
  <div class="available-card">
    <div class="players-wrapper">
      <div>
        <div class="blind-wrapper">
          <img class="blind-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
          <p class="blind-number">{{ formatNumber(room.big_blind_bet) }}</p>
        </div>
        <p class="blind-label">{{ t(`main.blind`) }}</p>
      </div>
    </div>
    <div class="players-wrapper">
      <div>
        <div class="blind-wrapper">
          <img class="blind-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
          <p class="blind-number">{{ room.game_deposit >= 1_000_000 ? formatNumber(room.game_deposit, 1) : formatNumber(room.game_deposit) }}</p>
        </div>
        <p class="blind-label">{{ t(`main.buy_in`) }}</p>
      </div>
    </div>
    <div>
      <p class="go">GO</p>
    </div>
  </div>
</template>

<style scoped>
.players-wrapper {
  display: flex;
  align-items: center;
}

.blind-balance-image {
  filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.5));
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.blind-info-wrapper {
  text-align: center;
}


.go {
  font-weight: 500;
  font-size: 28px;
}

.blind-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blind-number {
  font-weight: 500;
  font-size: 16px;
}

.blind-label {
  font-weight: 500;
  font-size: 13px;
}

.players-label {
  font-weight: 500;
  font-size: 10px;
}

.players-count {
  font-weight: 500;
  font-size: 20px;
}


.available-card {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-mixed);
  height: 70px;
  align-items: center;
  background-color: #5C4E73;
  padding-left: 21px;
  padding-right: 20px;
}
</style>