<script setup lang="ts">
import Player from "@/models/player";
import Game from "@/models/game";
import { defineProps } from "vue";

const props = defineProps<{
  player: Player,
  game: Game,
  timer: number
}>()

</script>

<template>
  <div class="user-count-wrapper" v-if="props.player.id == game?.current_player_id">
    <p class="user-count">{{ timer }}</p>
  </div>
</template>

<style scoped>

.user-count-wrapper {
  position: absolute;
  top: -7px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 5px;
  background-color: #7861A5;
  border-radius: 30px;
  height: 10px;
  width: 10px;
}

.user-count {
  font-size: 10px;
}
</style>