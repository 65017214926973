<script setup lang="ts">
import router from "@/router";
import {onMounted, ref, computed, defineProps} from "vue";
import {RoomService} from "@/api/roomService";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import RoomsListItem from "@/components/lists/rooms/RoomsListItem.vue";
import store from "@/store";
import RoomsItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  max_len: number,
  all: boolean;
  random?: boolean
}>();

function scrollToTop() {
  window.scrollTo(0,0);
  triggerHapticFeedback('light');
  router.push('/rooms')
}

let rooms = ref<RoomsItem[]>([]);
let isLoaded = ref(false);

const shuffle = (array: RoomsItem[]): RoomsItem[] => {
  return array.slice().sort(() => Math.random() - 0.5);
}

onMounted(async () => {
  const roomService = new RoomService();
  await roomService.rooms(props.max_len ?? 10)
      .then(response => response.json())
      .then(data => {
        rooms.value = data;
        if (props.random) {
          rooms.value = shuffle(rooms.value)
              .slice(0, 3)
              .sort((a, b) => b.small_blind_bet - a.small_blind_bet)
              .reverse();
        } else {
          rooms.value = rooms.value.slice(0, props.max_len);
        }
        isLoaded.value = true;
      });
});

const tryJoinRoom = (room: RoomsItem) => {
  router.push(`/game?small_blind_bet=${room.small_blind_bet}`);
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const isSecondSectionBackground = computed(() => !props.all);
</script>

<template>
  <section :class="{ 'second-section': true, 'second-section-background': !all}">
    <div 
      class="start-game" 
      :style="isSecondSectionBackground ? null : { 'justify-content': 'center' }"
    >
    <p class="start-game-label springy-animation"
        :style="isSecondSectionBackground ? { 'margin-right': '15px', 'margin-left': '7px' } : null"
      >
        {{ t(isSecondSectionBackground ? 'main.start_game' : 'main.choose_blind') }}
    </p>
      <div class="game-hr" v-if="isSecondSectionBackground"></div>
    </div>
    <div class="available" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in rooms" :key="i" @click="triggerHapticFeedback('light'); tryJoinRoom(room)" :room="room"/>
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="all-rooms" @click="scrollToTop" v-if="!all">
      <div>
        <p>{{ t(`main.all_rooms`) }}</p>
      </div>
      <div>
        <img src="@/assets/tools/arrow.svg">
      </div>
    </div>
  </section>
</template>

<style scoped>
.second-section {
  border-radius: var(--border-mixed);
  margin-top: 25px;
  padding: 25px 0 11px;
}

.second-section-background {
  background-color: #15151E;
  padding: 25px 11px 11px;
}

.game-hr {
  border-top: 1px solid white;
  width: 40%;
}

.start-game-label {
  font-weight: 500;
  font-size: 17px;
  white-space: nowrap;
}

.start-game {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.available-card-skeleton {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-mixed);
  height: 70px;
  align-items: center;
}

.all-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
  border-radius: var(--border-mixed);
  margin-top: 19px;
  background: linear-gradient(270.01deg, #5C4E73 0.01%, #5C4E73 0.02%, rgba(36, 35, 46, 0) 100%), #5C4E73;
}
</style>