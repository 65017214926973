<script setup lang="ts">
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import {ApiService} from "@/api/apiService";
import store from "@/store";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  triggerHapticFeedback('light');
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  triggerHapticFeedback('light');
  off('back_button_pressed', listener);
})

</script>

<template>
  <div class="levels-wrapper">
    <img src="@/assets/logo/logo.svg">
    <div class="levels-list">
      <div class="levels-list-item">
        <div class="level-img-wrapper">
          <p class="level-number">{{ t(`levels.level_1`) }}</p>
          <div class="level-decor">
            <div class="level-user">
              <div>
                <img src="@/assets/player/status/status-usual.svg" style="visibility: hidden">
                <div class="level-user-image-wrapper" style="background: white">
                  <img class="level-user-image" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
                </div>
              </div>
              <div class="level-cards-wrapper">
                <img class="player-card-1 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
                <img class="player-card-2 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="level-pros-wrapper">
          <div class="level-pros-list">
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.to_achive`) }}</p>
                <p class="level-pros-info-up">{{ t(`levels.top_up_by`) }}</p>
                <br>
                <p class="level-pros-sum">1&nbsp;TON</p>
            </div>
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.withdraw_fee`) }}</p>
              <p class="level-pros-sum">5%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="levels-list-item">
        <div class="level-img-wrapper">
          <p class="level-number">{{ t(`levels.level_2`) }}</p>
          <div class="level-decor">
            <div class="level-user">
              <div>
                <img src="@/assets/player/status/status-usual.svg">
                <div class="level-user-image-wrapper" style="background: white">
                  <img class="level-user-image" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
                </div>
              </div>
              <div class="level-cards-wrapper">
                <img class="player-card-1 no-image-animation" src="@/assets/cards/hidden/hidden-usual.svg">
                <img class="player-card-2 no-image-animation" src="@/assets/cards/hidden/hidden-usual.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="level-pros-wrapper">
          <div class="level-pros-list">
            <div>
              <div class="level-pros-item">
                <p class="level-pros-info">{{ t(`levels.to_achive`) }}</p>
                <p class="level-pros-info-up">{{ t(`levels.top_up_by`) }}</p>
                <p class="level-pros-sum">10&nbsp;TON</p>
              </div>
              <div class="level-pros-item">
                <p class="level-pros-info">{{ t(`levels.or_win`) }}</p>
                <p class="level-pros-sum">{{ t(`levels.game_10`) }}</p>
              </div>
            </div>
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.withdraw_fee`) }}</p>
              <p class="level-pros-sum">4%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="levels-list-item">
        <div class="level-img-wrapper">
          <p class="level-number">{{ t(`levels.level_3`) }}</p>
          <div class="level-decor">
            <div class="level-user">
              <div>
                <img src="../assets/player/status/status-silver.svg">
                <div class="level-user-image-wrapper" style="background: var(--gradient-sliver)">
                  <img class="level-user-image" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
                </div>
              </div>
              <div class="level-cards-wrapper">
                <img class="player-card-1 no-image-animation" src="@/assets/cards/hidden/hidden-silver.svg">
                <img class="player-card-2 no-image-animation" src="@/assets/cards/hidden/hidden-silver.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="level-pros-wrapper">
          <div class="level-pros-list">
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.to_achive`) }}</p>
                <p class="level-pros-info-up">{{ t(`levels.top_up_by`) }}</p>
                <p class="level-pros-sum">50&nbsp;TON</p>
            </div>
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.withdraw_fee`) }}</p>
              <p class="level-pros-sum">3%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="levels-list-item">
        <div class="level-img-wrapper">
          <p class="level-number">{{ t(`levels.level_4`) }}</p>
          <div class="level-decor">
            <div class="level-user">
              <div>
                <img src="@/assets/player/status/status-gold.svg">
                <div class="level-user-image-wrapper" style="background: var(--gradient-gold)">
                  <img class="level-user-image" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
                </div>
              </div>
              <div class="level-cards-wrapper">
                <img class="player-card-1 no-image-animation" src="@/assets/cards/hidden/hidden-gold.svg">
                <img class="player-card-2 no-image-animation" src="@/assets/cards/hidden/hidden-gold.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="level-pros-wrapper">
          <div class="level-pros-list">
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.to_achive`) }}</p>
                <p class="level-pros-info-up">{{ t(`levels.top_up_by`) }}</p>
                <p class="level-pros-sum">200&nbsp;TON</p>
            </div>
            <div class="level-pros-item">
              <p class="level-pros-info">{{ t(`levels.withdraw_fee`) }}</p>
              <p class="level-pros-sum">2%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.levels-wrapper {
  margin-bottom: 130px;
  margin-top: 15px;
}

.levels-list {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 33px;
}

.levels-list-item {
  height: 162px;
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
}

.level-number {
  font-size: 16px;
  font-weight: 500;
  color: #B9B9B9;
  text-align: left;
}

.level-pros-wrapper {
  text-align: left;
  margin-left: -100%;
}

.level-pros-sum {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
}

.level-pros-info {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
}

.level-user {
  display: flex;
  margin-top: 10px;
}

.level-user-image-wrapper {
  position: relative;
  z-index: 3;
  margin-bottom: 30px;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  flex-shrink: 0;
}

.level-user-image {
  border-radius: 50%;
  height: 76px;
  width: 76px;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
  margin-left: 2px;
}

.level-cards-wrapper {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  margin-top: 15px;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
}

.player-card-1 {
  position: relative;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  height: 80px;
  transform: rotate(14deg) translatez(0);
  transform-origin: 50%  51%;
  left: -25px;
  top: -20px;
  z-index: 1;
  animation-name: show-cards;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-duration: 2s;
}

.player-card-2 {
  position: relative;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  height: 80px;
  transform: rotate(35deg) translatez(0);
  transform-origin: 50%  51%;
  left: -60px;
  bottom: -15px;

}

.level-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.level-pros-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.level-pros-info-up{
  float: left;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
}
</style>