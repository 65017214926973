<script setup lang="ts">
import {ApiService} from "@/api/apiService";
import {formatNumber} from "@/tools/tools";
import TopPlayer from "@/models/topPlayer";
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps<{
  item: TopPlayer
}>();
</script>

<template>
  <div class="top-player-card">
    <div class="top-player-card-info">
      <div class="top-player-image-wrapper">
        <img class="top-player-image" :src="item.user_photo_url ? ApiService.url + item.user_photo_url : require('@/assets/mock_user/user.svg')">
      </div>
      <div class="top-player-info-wrapper">
        <div class="top-player-user-wrapper">
          <p class="top-player-card-username">{{ (item.user_username ?? 'noname').charAt(0) }}•••</p>
          <img v-if="item.vip_status !== 'first' && item.vip_status != null" :src="require(`@/assets/player/status/status-${item.vip_status}`)">
        </div>
        <div class="top-players-wrapper">
          <p class="players-label">{{ t(`main.wins_player_top`) }}:&nbsp;</p>
          <p class="players-count">{{ item.won_games }}/{{ item.total_games }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="player-win-sum">
        <div>
          <p class="player-win-sum-label">{{ formatNumber(item.total_won) }}</p>
        </div>
        <img src="@/assets/logo/money_logo.svg" class="top-player-balance-sum-logo">
      </div>
    </div>
  </div>
</template>

<style scoped>
.top-player-card {
  background: #5C4E73;
  border-radius: var(--border-mixed);
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.top-player-image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  filter: blur(1px);
  margin-right: 19px;
}

.top-player-card-info {
  display: flex;
  align-items: center;
}

.top-player-card-username {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.top-players-wrapper {
  display: flex;
  align-items: flex-end;
}

.player-win-sum {
  display: flex;
  align-items: center;
}

.player-win-sum-label {
  font-size: 18px;
  font-weight: 500;
}

.top-player-balance-sum-logo {
  margin-left: 9px;
  filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.5));
}
</style>