<script setup lang="ts">

import TransactionsItem from "@/components/lists/transactions/TransactionsItem.vue";
import {onMounted, ref} from "vue";
import {UserService} from "@/api/userService";
import Transaction from "@/models/transaction";

const transactions = ref<Transaction[]>([]);
const isLoaded = ref<boolean>(false);

onMounted(() => {
  const userService = new UserService();
  userService.transactions()
      .then(response => response.json())
      .then(data => {
        transactions.value = data;
        isLoaded.value = true;
      })
})
</script>

<template>
  <div class="transactions" v-for="(transaction, i) in transactions" :key="i">
    <transactions-item :transaction="transaction"/>
  </div>
</template>

<style scoped>
</style>