<template>
    <div class="message-recived" v-if="message.user_id != myPlayer.user_id && message.type === 'message'">
        <div class="user-img">
            <img :src="message.user_photo_url!" alt="">
        </div>
        <div class="content-wrapper">
            <div class="name">
                <p class="nickname">{{ message.user_username }}</p>
                <div class="crown">
                </div>
            </div>
            <p class="text">{{ message.body }}</p>
            <div class="time_stamp">
                <p>{{ setDate(message.created_at) }}</p>
            </div>
        </div>
    </div>
    <div class="message-send" v-else-if="message.type === 'message'">
        <div class="content-wrapper">
            <div class="name">
                <p class="nickname">{{ message.user_username }}</p>
                <div class="crown">
                </div>
            </div>
            <p class="text">{{ message.body }}</p>
            <div class="time_stamp">
                <p>{{ setDate(message.created_at) }}</p>
            </div>
        </div>        
    </div>
</template>

<script setup lang="ts">
import Message from "@/models/message";
import Player from "@/models/player";
import {defineProps} from "vue";

const props = defineProps<{
  message: Message,
  myPlayer: Player,
}>()

function setDate(dateTime:string){
    const date = new Date(dateTime);

    const moscowOffset = 3 * 60 * 60 * 1000; 
    const moscowTime = new Date(date.getTime() + moscowOffset);

    const hours = moscowTime.getUTCHours();
    const minutes = moscowTime.getUTCMinutes();
    
    const time = `${hours}:${minutes.toString().padStart(2, '0')}`;
    return time;
}

</script>


<style scoped>

.message-recived{
    display: flex;
    align-items: flex-end;
}

.message-send{
    display: flex;
    justify-content: flex-end;
}


.content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 6px 15px;
    background-color: #5C4E73;
    border-radius: 4vw;
    width: 52vw;
}

.name{
    display: flex;
    align-items: center;
}

.nickname{
    color: #B9B9B9;
    font-size: 10px;
}
.user-img{
    width: 10vw;
    min-width: 10vw;
    height: 10vw;
    border-radius: 100%;
    background-color: coral;
    margin-right: 6px;
}

.content-wrapper p.text{
    color: #fff;
    font-size: 14px;
    text-align: left;
}

.time_stamp{
    display: flex;
    justify-content: flex-end;
}

.time_stamp p{
    color: #B9B9B9;
    font-size: 10px;
    margin-top: -3vw;
    margin-right: -1vw;
}


.crown{
    width: 2vw;
    color: red;
}

</style>