<script setup lang="ts">
import store from "@/store";
import PlayerCard from "@/models/playerCard";
import Player from "@/models/player";
import Game from "@/models/game";
import {computed, onMounted, defineProps, defineEmits } from "vue";
import {formatNumber} from "../../tools/tools";
import Room from "@/models/room";
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  selectedEmoji: any,
  showPlayer: boolean
  selectedEmojiPlayerID: number
  playerCards: PlayerCard[],
  players: Player[],
  player: Player,
  hand: string,
  game: Game,
  room: Room,
  sliderValue: number,
  waitForJoin: boolean,
  dealerSeat: number | null
  joinRoom: () => void,
  min: number,
}>();

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

const emit = defineEmits(['call', 'raise', 'check', 'bet', 'fold', 'updateSliderValue']);

const borderColor = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return 'white'
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)"
    default:
      return 'white';
  }
});



onMounted(async () => {
  const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[0].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[0].card_suit.toLowerCase(): props.playerCards[0].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[1].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[1].card_suit.toLowerCase(): props.playerCards[1].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  
  const image1El = document.getElementById('player-card-1')
  const image2El = document.getElementById('player-card-2')

  await preloadImage(image1);
  await preloadImage(image2);

  if (image1El && image2El) {
    image1El.setAttribute('src', image1);
    image2El.setAttribute('src', image2);

    image1El.style.animation = 'half-flip-1 .45s ease forwards';
    image2El.style.animation = 'half-flip-2 .45s ease forwards';
  }
})


const showGameUserPlayer = computed(() => {
  return (props.player?.action_status === 'watcher') || props.waitForJoin;
});


const isRaiseInactive = computed(() => {
  const myPlayer = props.player;
  const currentMaxBet = Math.max(...props.players.map(player => player.current_round_bet));
  return myPlayer.game_deposit <= currentMaxBet;
});


function addSliderValue(){
  if (isRaiseInactive.value) return;
  triggerHapticFeedback('light');
  if (props.sliderValue < props.min){
    emit('updateSliderValue', props.min + props.room.big_blind_bet)
  } else {
    emit('updateSliderValue', props.sliderValue + props.room.big_blind_bet)
  }
}

function delSliderValue(){
  if (isRaiseInactive.value) return;
  triggerHapticFeedback('light');
  emit('updateSliderValue', props.sliderValue - props.room.big_blind_bet)
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <div class="game-actions">
    <div class="user-hand-wrapper">
        <p class="user-hand">{{ hand }}</p>
      </div>
    <div class="user-info-wrapper">
      <div class="user-info">
       <div class="elements-player-center">
        <div class="user-player">
        <game-user-player v-if="showGameUserPlayer"
              :timer="timer"
              :class="{ 'game-user-player': true, 'game-user-player-absolute': showGameUserPlayer }"
              :game="game!"
              :join-room="joinRoom"
              :wait-for-join="waitForJoin" 
              :selectedEmojiPlayerID="selectedEmojiPlayerID"
              :player="getMyPlayer!"
              :selected-emoji="selectedEmoji"
              :seat="seat"
              :showPlayer="showPlayer"
              :current-case="currentCase"
              :winners="winners"
              />
        </div>
        <div class="player-cards-wrapper">
          <img :style="{ 'opacity': (player.action_status == 'watcher') ? '0' : '1' }" id="player-card-1" class="player-card-1 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
          <img :style="{ 'opacity': (player.action_status == 'watcher') ? '0' : '1' }" id="player-card-2" class="player-card-2 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
        </div>
        <div class="game-button-raise-wrapper-watcher" v-if="(player.action_status != 'watcher') || waitForJoin">
          <div @click="addSliderValue" :class="{ 'game-button-raise1': true, 'game-button-inactive1': isRaiseInactive || (player.id != game?.current_player_id) }">+{{ formatNumber(room.big_blind_bet) }}</div>
          <div @click="delSliderValue" :class="{ 'game-button-raise1': true, 'game-button-inactive1': isRaiseInactive || (player.id != game?.current_player_id) }">-{{ formatNumber(room.big_blind_bet) }}</div>
        </div>
        <div class="game-button-raise-wrapper-watcher" v-else>
          <div></div>
          <div></div>
        </div>
       </div>
      </div>
    </div>
      <div v-if="game?.current_player_id">
        <div class="game-buttons" v-if="(player.action_status != 'watcher') || waitForJoin">
        <div :class="{ 'game-button': true, 'game-button-inactive': (player.id != game?.current_player_id) }" @click="triggerHapticFeedback('light'); (player.id == game?.current_player_id) ? emit('fold') : null">{{ t(`game.btn_fold`) }}</div>
        <div v-if="(player.current_round_bet) >= Math.max(...players.map(x => x.current_round_bet))" :class="{ 'game-button': true, 'game-button-inactive': (player.id != game?.current_player_id) }" @click="triggerHapticFeedback('light'); (player.id == game?.current_player_id) ? emit('check') : null">{{ t(`game.bnt_check`) }}</div>
        <div v-else :class="{ 'game-button': true, 'game-button-inactive': (player.id != game.current_player_id) }" @click="triggerHapticFeedback('light'); ( player.id == game?.current_player_id) ? emit('call') : null">{{ t(`game.btn_call`) }}</div>
        <div :class="{ 'game-button-raise-wrapper': true }">
          <div v-if="(player.current_round_bet) >= Math.max(...players.map(x => x.current_round_bet))" :class="{ 'game-button-raise': true, 'game-button-inactive': isRaiseInactive || (player.id != game?.current_player_id) }" @click="triggerHapticFeedback('light'); (player.id == game?.current_player_id && !isRaiseInactive) ? emit('bet') : null">{{ t(`game.btn_bet`) }}</div>
          <div v-else :class="{ 'game-button-raise': true, 'game-button-inactive': isRaiseInactive || (player.id != game?.current_player_id) }" @click="triggerHapticFeedback('light'); (player.id == game?.current_player_id && !isRaiseInactive) ? emit('raise') : null">{{ t(`game.btn_raise`) }}</div>
        </div>
      </div>
      <div class="game-buttons" v-else>       
          <div class="join-table" @click=" joinRoom()">
            {{ t(`game.join_room`) }}
          </div>
      </div>
    </div>
      <div v-else>
        <div class="game-buttons" v-if="(player.action_status != 'watcher') || waitForJoin">
        <div :class="{ 'game-button': true, 'game-button-inactive': true }">{{ t(`game.btn_fold`) }}</div>
        <div :class="{ 'game-button': true, 'game-button-inactive': true }">{{ t(`game.btn_call`) }}</div>
          <div :class="{ 'game-button-raise-wrapper': true }">
            <div :class="{ 'game-button-raise': true, 'game-button-inactive': true }">{{ t(`game.btn_bet`) }}</div>
          </div>
        </div>
        <div class="game-buttons" v-else>       
            <div class="join-table" @click="triggerHapticFeedback('light'); joinRoom()">
              {{ t(`game.join_room`) }}
            </div>
        </div>
      </div>
  </div>
</template>

<style scoped>

.user-player {
  position: absolute;
  left: 25vw;
}

.game-button-raise1 {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #5C4E73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  margin-top: 0.54vh;
}

.game-button-inactive1 {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #5C4E73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
}

.game-actions {
  background-color: #00000030;
  border-radius: var(--border-mixed);
  max-width: 293px;
  width: 68.14vw;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.user-info {
  display: flex;
  width: 77.67vw;
  justify-content: end;
}

.user-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.user-hand {
  font-size: 1.95vh;
  width: 92px;
  text-align: end;
  height: 30px;
}

.game-user-image {
  padding: 3px;
  border-radius: 50%;
  width: 11.3vh;;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
}

.user-hand-wrapper {
  width: 80%;
  display: flex;
  justify-content: end;
  margin-top: 10px;
  height: 30px;
}

.elements-player-center {
  display: flex;
  align-items: end;
  gap: 20px;
}

.player-card-1 {
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  width: 85px;
  transform: rotate(-8deg);
}

.player-card-2 {
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  width: 85px;
  transform: rotate(17deg);
  margin-left: -25px;
  margin-top: 20px;
}

.player-cards-wrapper {
  z-index: 10;
  display: flex;
  margin-top: -25px;
}

.game-user-image-wrapper {
  position: relative;
  z-index: 3;
  padding: 2px;
}

.game-buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 77.67vw;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 5px;
}

.game-button {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 32%;
  background: #5C4E73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
}

.game-button-raise {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #5C4E73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  margin-top: 0.54vh;
}

.game-button-raise-wrapper {
  width: 32%;
}

.game-button-raise-wrapper-watcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 1;
  width: 24.88vw;
}

.game-button-inactive {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #5C4E73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
}

.game-user-image-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.user-username-bet-wrapper {
  z-index: 2;
  bottom: -17px;
  position: absolute;
  width: 100%;
}

.user-username {
  font-size: 1.4vh;
  font-weight: 500;
  z-index: 2;
}

.user-balance {
  font-size: 1.5vh;
  padding: 0.5vh;
  background: #5C4E73;
  box-shadow: 0 5px 62px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
}

.user-corona {
  height: 3vh;
  position: absolute;
  top: calc(-9px - 2.5vh);
  width: 100%;
}

.join-table, .show-cards, .fold-cards {
  background: #5C4E73;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: center;
  margin-top: 0.54vh;
}
</style>