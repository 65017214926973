<script setup lang="ts">

import HeaderMini from "@/components/header/HeaderMini.vue";
import {computed, nextTick, onMounted, onUnmounted, ref, watch, inject} from "vue";
import store from "@/store";
import {useRoute} from "vue-router";
import {RoomService} from "@/api/roomService";
import Room from "@/models/room";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import router from "@/router";
import Player from "@/models/player";
import Game from "@/models/game";
import GameCard from "@/models/gameCard";
import PlayerCard from "@/models/playerCard";
import {UserService} from "@/api/userService";
import BetSlider from "@/components/game/BetSlider.vue";
import GameActions from "@/components/game/GameActions.vue";
import PlayerItem from "@/components/game/PlayerItem.vue";
import TableCards from "@/components/game/TableCards.vue";
import Message from "@/models/message";
import NotificationModal from "@/components/game/NotificationModal.vue";
import EmojiPlayer from "@/components/game/EmojiPlayer.vue"
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import ChatWrapper from "@/components/game/ChatWrapper.vue";
import ChipsPopup from "@/components/game/ChipsPopup.vue";

const playCheckSound = inject<() => void>("playCheckSound");

const playSound = inject<() => void>("playSound");

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

type Seat = {
  player: Player | null,
  seat: number
}

const messages = ref<Message[]>([]);

let selectedEmoji = ref<any>();
let selectedEmojiPlayerID = ref<number>();
let messageData = ref<any>();
let newMessage = ref<boolean>(false);

const room = ref<Room>();
const players = ref<Player[]>([]);
const seats = ref<Seat[]>([]);
const game = ref<Game | null>(null);
const gameCards = ref<GameCard[]>(getClearedGameCards());

const playerCards = ref<PlayerCard[]>([]);
const sliderValue = ref(1);
const isLoaded = ref(false);
const roomService = new RoomService();
const userService = new UserService();
const timer = ref<number>(0);

const moveUser = ref<any | undefined>(undefined)
const winners = ref<any[] | undefined>(undefined);
const hand = ref<string>('');
const action = ref<any | undefined>(undefined);
const waitForJoin = ref(false);
const waitForAction = ref(false);

const showChipsPopup = ref(false);

const notificationType = ref<"move" | "winner" | "new_msg" | null>(null);

const showChatComponent = ref(false); 

const handleChatClick = () => {
  showChatComponent.value = true; 
};

const handleCloseChat = () => {
  showChatComponent.value = false; 
};

const handleCloseChipsPopup = () => {
  showChipsPopup.value = false;
}

let timerInterval: ReturnType<typeof setInterval> | null = null;

const listener: MiniAppsEventListener<'back_button_pressed'> = () => {
  if (room.value?.id && getMyPlayer.value) {
    triggerHapticFeedback('soft');
    roomService.leaveRoom(room.value.id);
  }
  router.push('/rooms');
};

const joinRoom = () => {
  if (!showChipsPopup.value) {
    showChipsPopup.value = true;
  } else {
    showChipsPopup.value = false;
    waitForJoin.value = true;
    roomService.joinRoom(room.value!.id)
        .then(response => {
          waitForJoin.value = false;
          if (response.status === 403) {
            router.push('/rooms');
            return;
          }
          if (response.status === 402) {
            router.push('/rooms?withBalancePopup=true');
            return;
          }
          return response.json();
        })
        .then(data => {
          if (!data["game"]) {
            players.value = data["players"];
            fillSeats();
          }
          isLoaded.value = true;
        });
    }
}

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  store.commit('SET_FOCUSED', true);
  let small_blind_bet = useRoute().query.small_blind_bet;
  if (!small_blind_bet) {
    return
  }
  roomService.room(parseInt(small_blind_bet.toString()), getFirstAvailableSeat.value!)
      .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        return response.json()
      })
      .then(data => {
        room.value = data["room"];
        sliderValue.value = room.value?.small_blind_bet ?? 0
        listenWebSockets();
        players.value = data["players"];
        messages.value = data["messages"];
        fillSeats();
        game.value = data["game"] ?? null;
        if (data["cards"]) {
          for (let i = 0; i < data["cards"].length; i++) {
            gameCards.value.splice(i, 1, data["cards"][i]);
          }
        }
        if (!room.value?.is_available && !getMyPlayer.value) {
          router.push('/rooms');
          return;
        }
        if (game.value && getMyPlayer.value?.status == 'active') {
          roomService.getPlayerCards()
              .then(response => response.json())
              .then(data => {
                playerCards.value = data;
                isLoaded.value = true;
              });
        } else {
          isLoaded.value = true;
        }
      })
      .catch((error) => {
        if (error.response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        else console.log(error.message);
      });
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
  store.commit('SET_FOCUSED', false)
});


function preflopAnim() {

    let preflopCards = [
      document.getElementById('preflop-1'), 
      document.getElementById('preflop-2'), 
      document.getElementById('preflop-3'), 
      document.getElementById('preflop-4'), 
      document.getElementById('preflop-5'), 
      document.getElementById('preflop-6'), 
      document.getElementById('preflop-7'), 
      document.getElementById('preflop-8'), 
      document.getElementById('preflop-9'), 
      document.getElementById('preflop-10'),
      document.getElementById('preflop-11'),
      document.getElementById('preflop-12'),   
      document.getElementById('preflop-13'), 
      document.getElementById('preflop-14'), 
      document.getElementById('preflop-15'), 
      document.getElementById('preflop-16'), 
    ]

    let baseCard = document.getElementById('preflop-base');

    nextTick(() => {
      const activePlayers = document.getElementsByClassName('not-watcher');
      
      if (activePlayers && baseCard) {
        for (let index = 0; index < activePlayers.length; index++) {
          const element = activePlayers[index] as HTMLElement;
          setTimeout(() => {    
            setTimeout(() => {
              setElementCenterToTarget((preflopCards[index*2] as HTMLElement), element, baseCard);
              setTimeout(() => {
                if (playSound) {
                  playSound(); 
                }    
                setElementCenterToTarget((preflopCards[index*2+1] as HTMLElement), element, baseCard);  
                if (index + 1 === activePlayers.length) {
                  setTimeout(() => {
                    baseCard.style.opacity = '0';
                  }, 600); 
                }
              }, 100);
            }, 100);
          }, index * 1000); 
        }
      } 
    }); 
}


function getElementCenter(element: HTMLElement): { x: number, y: number } {
  const rect = element.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
}


function setElementCenterToTarget(elementToMove: HTMLElement, targetElement: HTMLElement, baseCard: HTMLElement) {

  const targetCenter = getElementCenter(targetElement);
  const elementCenter = getElementCenter(elementToMove);

  const deltaX = targetCenter.x - elementCenter.x;
  const deltaY = targetCenter.y - elementCenter.y;

  const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) + 90;

  elementToMove.style.transition = 'transform 0.1s ease'; 
  elementToMove.style.transform = `rotate(${angle}deg)`;
  baseCard.style.transition = 'transform 0.3s ease'; 
  baseCard.style.transform = `rotate(${angle}deg)`;

  const computedOpacity = getComputedStyle(baseCard).opacity;
  
  if (computedOpacity === '0') {
    baseCard.style.opacity = '100';
  }


  setTimeout(() => {
    elementToMove.style.opacity = '100';
    setTimeout(() => {
      const currentLeft = parseFloat(getComputedStyle(elementToMove).left) || 0;
      const currentTop = parseFloat(getComputedStyle(elementToMove).top) || 0;

      const newLeft = currentLeft + deltaX;
      const newTop = currentTop + deltaY;

      const animationName = `moveAnimation_${Date.now()}`;

      const keyframes = `
        @keyframes ${animationName} {
          0% {
            opacity: 1;
            transform: rotate(${angle}deg) scale(1, 1);
            left: ${currentLeft}px;
            top: ${currentTop}px;
          }
          80% {
          opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(${angle}deg) scale(0.5, 0.5);
            left: ${newLeft}px;
            top: ${newTop}px;
          }
        }
      `;

      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerHTML = keyframes;
      document.head.appendChild(styleSheet);

      elementToMove.style.animation = `${animationName} 0.5s forwards`; 
    }, 300);
  }, 300); 
}

// WEBSOCKETS

const currentCase = ref<string | null>(null);

function listenWebSockets() {
  (window as any).Echo.channel('game-updated.' + room.value!.id).listen('NotifyRoom', (e: any) => {
    if (e.room.id != room.value!.id) {
      return;
    }
    console.log(e.message);
    switch (e.message.action) {
      case 'players_update':
        currentCase.value = 'players_update';
        players.value = e.message.players;
        fillSeats();
        room.value = e.room;
        break;
      case 'new_game':
        currentCase.value = 'new_game';
        game.value = e.message.game;
        players.value.forEach((player: Player) => {
          if (getMyPlayer.value?.user_id == player.id
              && player.game_deposit < room.value!.big_blind_bet
              && store.getters.USER.balance < room.value!.game_deposit) {
            router.push('/rooms?withBalancePopup=true')
          }
        });
        getPlayerCards();
        gameCards.value = getClearedGameCards();
        waitForAction.value = false;
        break;
        
      case 'show_down':
        currentCase.value = 'show_down';
        if (game.value){
          game.value.current_player_id=undefined;
        }
        hand.value = e.message.winnerHand ?? 'нет';
        handlePlayerCards(e.message.playerCards);
        winners.value = undefined;
        winners.value = e.message.winners.map((element: any) => {
          return {
            user_id: element.user_id,
            username: element.user_username,
            sum: element.sum,
            handRanking: element.handRanking,
            created_at: element.created_at
          }
        });
        setTimeout(() => {
          notificationType.value = 'winner';
        }, 5000);
        break;
      case 'preflop':
        winners.value = undefined;
        players.value = e.message.players;
        fillSeats();
        preflopAnim();
        break;
      case 'finish':
        currentCase.value = 'finish';
        gameCards.value = getClearedGameCards();
        updateUser();
        playerCards.value = [];
        game.value = null;
        moveUser.value = undefined;
        hand.value = e.message.winnerHand ?? 'нет';
        waitForAction.value = false;
        break;
      case 'game_stopped':
        currentCase.value = 'game_stopped';
        game.value = null;
        players.value = e.message.players;
        players.value.forEach(player => {
          if (player===getMyPlayer.value){
            if (player.status==='inactive') showChipsPopup.value = true;
          }
        });
        fillSeats();
        playerCards.value = [];
        if (!getMyPlayer.value) {
          router.push('/rooms');
        }

        waitForAction.value = false;
        break;
      case 'check':
        currentCase.value = 'check';
        game.value = e.message.game;
        notificationType.value = 'move';
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'чек',
        }
        players.value = e.message.players;
        if (playCheckSound) {
          playCheckSound(); 
        }
        fillSeats();
        resetTimer();
        
        waitForAction.value = false;
        break;
      case 'bet':
        currentCase.value = 'bet';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'бет',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        players.value = e.message.players;
        resetTimer();
        fillSeats();
        updateUser();

        waitForAction.value = false;
        break;
      case 'raise':
        currentCase.value = 'raise';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'рейз',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        players.value = e.message.players;
        resetTimer();
        fillSeats();
        updateUser();

        waitForAction.value = false;
        break;
      case 'pre_flop_all_in':
        currentCase.value = 'pre_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        gameCards.value.splice(3, 1, e.message.cards[3]);
        gameCards.value.splice(4, 1, e.message.cards[4]);

        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'ва-банк',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        resetTimer();
        fillSeats();
        updateUser();

        waitForAction.value = false;
        break;
      case 'post_flop_all_in':
        currentCase.value = 'post_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        for (let i = 0; i < gameCards.value.length; i++) {
          const gameCard = gameCards.value[i];
          if (!gameCard.is_opened) {
            gameCards.value.splice(i, 1, e.message.cards.pop());
          }
        }
        gameCards.value.push(e.message.cards);

        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'ва-банк',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        resetTimer();
        fillSeats();
        updateUser();

        waitForAction.value = false;
        break;
      case 'fold':
        currentCase.value = 'fold';
        game.value = e.message.game;
        action.value = undefined;
        notificationType.value = 'move';
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'фолд',
        }
        players.value = e.message.players;
        resetTimer();
        fillSeats();

        waitForAction.value = false;
        break;
      case 'call':
        currentCase.value = 'call';
        game.value = e.message.game;

        action.value = undefined;
        notificationType.value = 'move';
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'колл'
        }
        players.value = e.message.players;
        resetTimer();
        fillSeats();
        updateUser();

        waitForAction.value = false;
        break;
      case 'post_flop':
        currentCase.value = 'post_flop';
        game.value = e.message.game;
        players.value = e.message.players;
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        break;
      case 'post_turn':
        currentCase.value = 'post_turn';
        game.value = e.message.game;
        players.value = e.message.players;
        gameCards.value.splice(3, 1, e.message.cards[0]);
        break;
      case 'post_river':
      currentCase.value = 'post_river';
        game.value = e.message.game;
        players.value = e.message.players;
        gameCards.value.splice(4, 1, e.message.cards[0]);
        break;
      case 'new_message':{
        if (e.message.messageData.type ==='message'){
          messageData = e.message.messageData;
          nextTick(() =>{
            notificationType.value = 'new_msg';
          })
          const roomID = room.value?.id;
          if (roomID){
            roomService.messages(roomID)
            .then(response => response.json())
              .then(data => {
                messages.value = data;
              });
          }
        }
        else {
          selectedEmoji.value = e.message.messageData.body;
          selectedEmojiPlayerID.value = e.message.messageData.user_id;
        }
        break;
      }
    }
  });
}

const dealerSeatNumber = ref<number | null>(null); 

function fillSeats() {
  seats.value = [];
  for (let i = 0; i < room.value!.max_players; i++) {
    seats.value.push({
      'player': getPlayerBySeat(i + 1) ?? null,
      'seat': i + 1
    });
  }
  if (game.value && game.value.dealer_id) {
    const dealerId = game.value.dealer_id;
    const dealerSeat = seats.value.find(seat => seat.player?.id === dealerId);
    if (dealerSeat) {
      dealerSeatNumber.value = dealerSeat.seat; 
    } else {
      dealerSeatNumber.value = null;  
    }
  } else {
    dealerSeatNumber.value = null;
  }
}

function getClearedGameCards() {
  console.log('Лог из getClearedGameCards')
  return [
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    }
  ];
}

// GETTERS

function getPlayerBySeat(seat: number) {
  return players.value.find((player: Player) => player && player.seat == seat);
}

function getPlayerCards() {
  console.log('Лог из getPlayerCards')
  roomService.getPlayerCards()
      .then(response => response.json())
      .then(data => {
        playerCards.value = data;
      });
}

// SETTERS

const getMaxBet = computed(() => {
  return getMyPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

const getMaxRaise = computed(() => {
  return getMyPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

function setSliderValue(value: any) {
  if (value < minSliderValue.value) {
    return;
  }
  if (value > getMaxBet.value) {
      return;
  }
  else {
    if (value > getMaxRaise.value) {
      return;
    }
  }
  sliderValue.value = value;
  return sliderValue.value;
}

// GAME LOGIC

const check = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.check()
}

const call = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.call()
}

const fold = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.fold()
}

const bet = async (bet: number) => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.bet(bet)
}

const raise = async (raise: number) => {
  waitForAction.value = true;
  game.value!.current_player_id = undefined;
  await roomService.raise(raise)
  console.log('Лог из raise, bet = '+raise)
}

// OTHER FUNCTIONS

function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
}

function resetTimer() {
  timer.value = 30;
}

// COMPUTED

const getSortedPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  
  const currentUserIndex = seats.value.findIndex(seat => seat.player?.user_id === currentUserId);
  
  if (currentUserIndex === -1) {
    return seats.value;
  }
  
  const beforeCurrentUser = seats.value.slice(0, currentUserIndex);
  const afterCurrentUser = seats.value.slice(currentUserIndex + 1);

  return [...afterCurrentUser, seats.value[currentUserIndex], ...beforeCurrentUser];
});

const getFilteredPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  return getSortedPlayersForDisplay.value.filter(seat => seat.player?.user_id !== currentUserId);
});

const getCurrentPlayer = computed(() => {
  const currentUserId = store.getters.USER.id;
  return seats.value.find(seat => seat.player?.user_id === currentUserId);
});

const minSliderValue = computed(() => {
  if (!room.value) {
    return 0;
  }
  if (getMyPlayer.value!.current_round_bet >= Math.max(...players.value.map(x => x.current_round_bet))) {
    return Math.max(...players.value.map(x => x.current_round_bet), room.value.small_blind_bet) ?? room.value.small_blind_bet;
  }
  return room.value.small_blind_bet;
});


const getMyPlayer = computed(() => {
  return players.value.find(player => player.user_id == store.getters.USER.id);
});

const getHand = computed(() => {
  if (playerCards.value.length == 0) {
    return '';
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  let Hand = require('pokersolver').Hand;
  const filteredPlayerCards = playerCards.value.map((playerCard: PlayerCard) => {
    let rank = playerCard.card_rank;
    let suit = playerCard.card_suit;

    if (rank == '10') {
      rank = 'T'
    }
    return rank + suit.charAt(0);
  });
  const filteredGameCards = gameCards.value
      .filter((gameCard: GameCard) => gameCard.is_opened)
      .map((gameCard: GameCard) => {
        let rank = gameCard.card_rank;
        let suit = gameCard.card_suit;

        if (rank == '10') {
          rank = 'T'
        }
        return rank + suit.charAt(0);
      });

  const cards = filteredPlayerCards.concat(filteredGameCards);
  let cardsArr = cards.filter((item, index) => {
    return cards.indexOf(item) === index
  });
  let hand = Hand.solve(cardsArr);

  const translate: { [key: string]: string } = {
    'Royal Flush': 'Роял Флеш',
    'Straight Flush': 'Стрит Флеш',
    'Four of a Kind': 'Каре',
    'Full House': 'Фулл Хаус',
    'Flush': 'Флеш',
    'Straight': 'Стрит',
    'Three of a Kind': 'Тройка',
    'Two Pair': 'Две Пары',
    'Pair': 'Пара',
    'High Card': 'Старшая Карта',
  };

  return translate[hand.name as keyof typeof translate] || 'Unknown';
});

const getFirstAvailableSeat = computed(() => {
  return seats.value.find(seat => !seat.player)?.seat ?? null;
});

// WATCHERS

watch(() => game.value?.last_move_at, (newPlayerId) => {
  if (newPlayerId !== undefined) {
    if (timerInterval) {
      clearInterval(timerInterval);
      timerInterval = null;
    }
    resetTimer();
    timerInterval = setInterval(() => {
      if (timer.value > 0) {
        timer.value--;
      }
    }, 1000);
  }
});

watch(minSliderValue, (newMin: number) => {
  if (sliderValue.value < newMin) {
    sliderValue.value = newMin;
  }
});

watch(() => game.value?.current_player_id, (newVal) => {
  const currentPlayer = players.value.find((el) => el.id == newVal);
  if (newVal && currentPlayer) {
    notificationType.value = 'move';
    moveUser.value = undefined;
    moveUser.value = {
      user_id: currentPlayer.user_id,
      username: currentPlayer.user_username,
      time: new Date().toString()
    }
  }
});

const showGameUserPlayer = computed(() => {
  return getMyPlayer.value?.action_status != 'watcher' || waitForJoin.value;
});


const canRaise = computed(() => {
  return game.value && game.value.current_player_id === getMyPlayer.value?.id;
});

const isBalanceEnoughForSlider = computed(() => {
  
  const myPlayer = getMyPlayer.value;
  const currentMaxBet = maxBet.value;

  const result = currentMaxBet <= myPlayer!.game_deposit ;

  return result;
});


const shouldRenderBetSlider = computed(() => {
  return canRaise.value && isBalanceEnoughForSlider.value;
});

const maxBet =  computed(() => {
  console.log(...players.value.map(player => player.current_round_bet))

  let result = Math.max(...players.value.map(player => player.current_round_bet))
  if (result === 0) {
    return room.value!.big_blind_bet;
  }
  return Math.max(...players.value.map(player => player.current_round_bet))*2;
});


let playerCardsState = ref([]);

function handlePlayerCards(playerCards: any) {
  playerCardsState = playerCards;
}

</script>

<template>
  <KeepAlive>
  <div>
    <header-mini/>
    <div class="game-g-wrapper">
      <notification-modal v-if="notificationType == 'move' && moveUser" :notification-type="'move'" :move-user="moveUser" :action="action" :key="moveUser ? moveUser.time : ''"/>
      <notification-modal v-if="notificationType && winners !== undefined" :notification-type="'winner'" :winners="winners" :key="winners.map(element => element.created_at).join(', ')" :hand="hand"/>
      <notification-modal v-if="notificationType== 'new_msg'"
        :notification-type="'new_msg'"
        :message-data="messageData"
      /> 
      <div class="game-wrapper" v-if="room && players && isLoaded" :key="game?.id">
        <div class="game-table-wrapper">
          <div class="first-players-line">
            <div v-for="seat in getFilteredPlayersForDisplay.slice(0, room.max_players / 2)" :key="seat.seat">
              <player-item 
              :seat="seat" 
              :timer="timer" 
              :game="game!"
              :dealer-seat="dealerSeatNumber"
              :is-second-line="false"
              :player-cards="playerCardsState"
              :current-case="currentCase"
              :selected-emoji="selectedEmoji"
              :selectedEmojiPlayerID="selectedEmojiPlayerID"
              :winners="winners"
              />
            </div>
          </div>
          <table-cards
              :first-flop-card="gameCards[0]"
              :second-flop-card="gameCards[1]"
              :third-flop-card="gameCards[2]"
              :tern-card="gameCards[3]"
              :river-card="gameCards[4]"
              :game="game!"
              :player="getMyPlayer!"
              :players="players"
              :players-count="room.max_players"
              />
          <div class="second-players-line">
            <div v-for="(seat, index) in getFilteredPlayersForDisplay.slice(room.max_players / 2).reverse()" :key="seat.seat">
              <player-item 
              :seat="seat" 
              :timer="timer" 
              :game="game!"
              :index="index"
              :dealer-seat="dealerSeatNumber"
              :is-second-line="true"
              :player-cards="playerCardsState"
              :current-case="currentCase"
              :selected-emoji="selectedEmoji"
              :selectedEmojiPlayerID="selectedEmojiPlayerID"
              :winners="winners"
              />
            </div>
          </div>
        </div>
        <div>
        <div class="user-player-action" :style="{ opacity: showGameUserPlayer ? 1 : 0 }">
          <game-user-player
            :player="getMyPlayer!"
            :timer="timer"
            :game="game!"
            :wait-for-join="waitForJoin"
            :selected-emoji="selectedEmoji"
            :player-cards="playerCards"
            :dealer-seat="dealerSeatNumber"
            :show-player="showGameUserPlayer"
            :selectedEmojiPlayerID="selectedEmojiPlayerID!"
            :current-case="currentCase"
            :winners="winners"
            />
        </div>
        <div class="elements-player">
          <div class="elements-player-lft">
          <game-actions
              @bet="() => bet(sliderValue)"
              @raise="() => raise(sliderValue)"
              @call="() => call()"
              @check="() => check()"
              @fold="() => fold()"
              @update-slider-value="(value) => setSliderValue(value)"
              :player-cards="playerCards"
              :game="game!"
              :room="room"
              :slider-value="sliderValue"
              :players="players"
              :player="getMyPlayer!"
              :hand="getHand"
              :key="playerCards.length"
              :join-room="joinRoom"
              :wait-for-join="waitForJoin"
              :wait-for-action="waitForAction"
              :selectedEmojiPlayerID="selectedEmojiPlayerID!"
              :selected-emoji="selectedEmoji"
              :show-player="showGameUserPlayer"
              :current-case="currentCase"
              :winners="winners"
              :min="maxBet"
            />
          </div>
          <div class="elements-player-rght" :style="{ opacity: showGameUserPlayer ? 1 : 0.4 }">
            <bet-slider v-if="shouldRenderBetSlider"
                @update-slider-value="(value) => setSliderValue(value)"
                :game="game!" 
                :players="players" 
                :room="room"
                :player="getMyPlayer!" 
                :min="maxBet"
                :value="sliderValue"/>
            <emoji-player v-else-if="isLoaded" 
            @chatClicked="handleChatClick"
            :room="room"
            :showPlayer="showGameUserPlayer"
            :message-data="messageData"
            />
            <chat-wrapper v-if="showChatComponent" 
              @closeChat="handleCloseChat"
              :myPlayer="getMyPlayer!"
              :room="room"
              :messages="messages"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <chips-popup v-if="showChipsPopup"
  @close="handleCloseChipsPopup"
  :room="room!"
  :join-room="joinRoom"
  :player="getMyPlayer!"
  />
</KeepAlive>
</template>

<style scoped>

.elements-player-rght {
  display: flex;
  align-items: end;
  z-index: 100;
  margin-left: 20px;
  justify-content: flex-end;
}

.elements-player {
  display: flex;
  margin-left: -25px;
  margin-top: -17px;
}

.user-player-action {
  display: flex;
  margin-top: -115px;
}

.game-table-wrapper {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 61px;
  border-radius: 20px;
  padding: 11px;
}

.first-players-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
  background: rgba(181, 152, 239, 0.2);
  padding-bottom: 100px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 349px;
  width: 81.16vw;
  padding-top: 15px; 
}

.second-players-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8vw;
  padding-bottom: 15px;
  padding-top: 120px;
  margin-top: -105px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(181, 152, 239, 0.2);
  max-width: 349px;
  width: 75.58vw;
  padding-right: 5.58vw;
}

.game-wrapper {
  position: relative;
}
</style>