/**
 * Форматирует число в компактный вид с использованием единиц измерения (K, M, B, T).
 * @param value 
 * @param numbers
 * @returns 
 */
export function formatNumber(value: number, numbers = 0): string {
    if (value >= 1_000_000_000_000) {
        return formatWithSuffix(value, 1_000_000_000_000, 'T', numbers); // триллионы
    } else if (value >= 1_000_000_000) {
        return formatWithSuffix(value, 1_000_000_000, 'B', numbers); // миллиарды
    } else if (value >= 1_000_000) {
        return formatWithSuffix(value, 1_000_000, 'M', numbers); // миллионы
    } else if (value >= 1_000) {
        return formatWithSuffix(value, 1_000, 'K', numbers); // тысячи
    } else {
        return value.toString();
    }
}

function formatWithSuffix(value: number, divisor: number, suffix: string, numbers: number): string {
    const formattedValue = value / divisor;
    return formattedValue % 1 === 0
        ? formattedValue.toFixed(0) + suffix
        : formattedValue.toFixed(numbers || 1) + suffix;
}

/**
 * Форматирует число по разрядам с разделителями пробелами.
 * @param value Число для форматирования.
 * @returns Строка с отформатированным числом.
 */
export function formatNumberByZiro(value: number): string {
    return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatLocalDate(utcPlus3DateString: string, withMinutes = false): string {
    const serverDate = new Date(utcPlus3DateString);
    const serverTimezoneOffset = 3 * 60 * 60000; // 3 часа в миллисекундах
    const utcDate = new Date(serverDate.getTime() - serverTimezoneOffset);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

    const day = String(localDate.getDate()).padStart(2, '0');
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Месяцы в JS начинаются с 0
    const year = localDate.getFullYear();
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    let returnString = `${day}.${month}.${year}`;
    if (withMinutes) {
        returnString += ` ${hours}:${minutes}:${seconds}`
    }

    return returnString;
}
