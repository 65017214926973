<script setup lang="ts">
import HeaderMini from "@/components/header/HeaderMini.vue";
import settings from "@/views/settings";
import SettingsItem from "@/components/settings/SettingsItem.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal.vue";
import UserAgreementModal from "@/components/modals/UserAgreementModal.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});

  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});
</script>

<template>
  <div class="settings">
    <header-mini/>
    <div class="settings-wrapper">
      <p class="settings-label springy-animation">{{ t('settings.settings') }}</p>
      <div class="setting-wrapper" v-for="(item, i) in settings" :key="i">
        <div v-if="item.type == 'switch'">
          <settings-item @click="settings[i].defaultState = !item.defaultState" :on="item.on" :off="item.off" :type="item.type" :label="item.label" :default-state="item.defaultState"/>
        </div>
        <div v-else>
          <settings-item :click="item.click" :type="item.type" :label="item.label"/>
        </div>
      </div>
    </div>
  </div>
  <delete-account-modal/>
  <user-agreement-modal/>
  
</template>

<style scoped>
.settings-wrapper {
  margin-top: 100px;
  margin-bottom: 80px;
}

.settings-label {
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.setting-wrapper {
  margin-top: 14px;
}

.settings {
  margin-bottom: 50px;
}
</style>