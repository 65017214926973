import { createI18n } from 'vue-i18n';

const savedLanguage = localStorage.getItem('selectedLanguage') || 'ru';

const messages = {
  en: {
    main: {
      rooms: 'Rooms',
      balance: "RedCoin:",
      in: 'in',
      a_week: 'a week',
      choose_blind: "Choose a blind, we'll find a room",
      start_game: 'Start the game',
      blind: 'Blind',
      buy_in: 'Buy-in',
      all_rooms: 'All rooms',
      top_of_hour: 'Top of hour',
      top_of_day: 'Top of the day',
      wins_player_top: 'Wins:'
    },
    top_up_modal: {
      warning_main: "Oops! You don't have enough chips in your balance.",
      warning_full: 'To play on this blind, you need more RedCoin',
      cancel_button: 'Invite friends'
    },
    friends: {
      invite_friends: 'Invite friends!',
      invite_friends_additional: 'And get a bonus to your balance!',
      invite_friend_label: 'Invite a friend',
      invite_friend_gift_bonus: '+50k RedCoin to your balance',
      friend_get_label1: 'Friends',
      friend_get_label2: 'are invited',
      friend_get_label_bonus1: 'Bonus',
      friend_get_label_bonus2: 'recived',
      rules_label: 'Bonus terms',
      rules_img_label: 'You will receive a reward for each friend who fulfilled the conditions',
      you_are_required: 'You are required:',
      register: 'Register',
      win_10_games_and: 'Share your link with a friend',
      a_friend_id_required_to: 'A friend id required to:',
      follow_your_link: 'Follow your link',
      win_10_games: 'Claim your daily bonus',
      list_of_your_friends: 'List of your friends:',
      button_invete: 'Invite a friend',
      level_2_friends: 'To invite friends, raise your level to 2',
      how_top_up: 'Find out how',
      invite_message: '🔥Play with me and get 500 GoCoin on balance💸'
    },
    game: {
      bank: 'Bank:',
      bet: 'Bet:',
      user_hand: '',
      btn_fold: 'Fold',
      bnt_check: 'Check',
      btn_call: 'Call',
      btn_bet: 'Bet',
      btn_raise: 'Raise',
      join_room: 'Sit at the table',
      all_in: 'all-in'
    },
    notification: {
      you_turn: 'Your turn to act',
      youve_won: 'Your winnings: ',
      action: 'Turn',
      the_winning_hand: 'The winning hand: ',
      won: " won's:",
      slice_bank: 'split the bank'
    },
    settings: {
      language: 'Language',
      vibration: 'Vibration',
      music: 'Music',
      sounds: 'Sounds',
      animation: 'Animation',
      deleteAccount: 'Delete Account',
      userAgreement: 'User Agreement',
      settings: 'Settings'
    },
    menu: {
      play: "Play",
      rooms: "Rooms",
      friends: "Friends",
      profile: "Profile",
      settings: "Settings"
    },
    profile: {
      balance: 'RedCoin:',
      levels: 'Levels',
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      transaction_history: 'Transaction history',
      transaction: 'Transaction',
      history: 'history',
      add: 'Add',
      withdraw2: 'Withdraw',
      game_history: 'Game history',
      no_history_label: "You haven't played any games yet. Start a game now:",
      all_rooms_label: 'All rooms',
      history_item_room_label: 'Blind',
      transaction_again_label: 'Repeat',
      operation_details_label: 'Transaction details:',
      status: 'Status',
      status_success: 'Success',
      withdraw_fee: 'Withdraw fee',
      daily_bonus: 'Daily Bonus',
      claim_bonus: 'Claim Bonus',
      box: 'To get even more RedCoin, invite your friends!',
      friends: 'Invite friends'
    },
    levels: {
      level_1: '1 level',
      level_2: '2 level',
      level_3: '3 level',
      level_4: '4 level',
      to_achive: 'To achive',
      top_up_by: 'top up by',
      withdraw_fee: 'Withdraw fee:',
      or_win: 'or win',
      game_10: '10 game'
    }, 
    level_up: {
      congr_1: 'Congratulations on moving to level',
      new_diz: '! The new design and reduced withdrawal fee are now yours!',
      view_all_levels: 'View all levels'
    },
    deposit_and_withdraw: {
      connect_wallet_label: 'To top up your balance, link your TON wallet',
      link_wwallet: 'Link TON wallet',
      you_are: 'You are',
      depositing: 'repositing',
      withdraw: 'withdraw',
      your_ton_wallet: 'Your TON wallet',
      your: 'Your',
      level: 'level',
      fee: 'Fee(2%)',
      reduce: 'Reduce',
      reduce_fee: 'the fee',
      result: 'Result: '
    },
    delete_account: {
      warning_main: "Are you sure you want to delete your account?",
      warning_ful: 'All your data, including game progress, achievements, and purchases, will be permanently deleted.',
      warning_full: 'This action cannot be undone',
      delete_button: 'Delete account',
      cancel_button: 'Cancel'
    },
    chips_popup: {
      go_coins: 'RedCoins',
      chose_go_coins: 'Choose RedCoins for the game',
      balance: 'Your balance:',
      minimun_bet: 'Minimum bet:',
      waiting: 'Waiting: ',
      waiting_time: ' sec',
      exit: 'Exit',
      start_game: 'Start the game',
      continue: 'Continue',
      add_balance: 'Invite friends'
    },
    daily_bonus_pop: {
      claim: 'Claim your daily bonus',
      claim_first: 'Claim your first bonus',
      claim_btn: 'Claim Bonus'
    }
  },
  ru: {
    main: {
      rooms: 'Комнаты',
      balance: "RedCoin:",
      in: 'за',
      a_week: 'неделю',
      choose_blind: 'Выбери блайнд, мы найдём комнату',
      start_game: 'Начать игру',
      blind: 'Блайнд',
      buy_in: 'Взнос',
      all_rooms: 'Все комнаты',
      top_of_hour: 'Топ за час',
      top_of_day: 'Топ за день',
      wins_player_top: 'Побед:'
    },
    top_up_modal: {
      warning_main: 'Упс! У тебя недостаточно фишек на балансе',
      warning_full: 'Чтобы играть на этом блайнде, нужно больше RedCoin',
      cancel_button: 'Пригласить друзей'
    },
    friends: {
      invite_friends: 'Пригласи друзей!',
      invite_friends_additional: 'И получи бонус на свой баланс!',
      invite_friend_label: 'Пригласить друга',
      invite_friend_gift_bonus: '+50k RedCoin на баланс',
      friend_get_label1: 'Друзей',
      friend_get_label2: 'приглашено',
      friend_get_label_bonus1: 'Бонусов',
      friend_get_label_bonus2: 'получено',
      rules_label: 'Условия',
      rules_img_label: 'Вы получите награду за каждого друга, который выполнил условия',
      you_are_required: 'От вас требуется:',
      register: 'Зарегистрироваться',
      win_10_games_and: 'Поделиться с другом своей ссылкой',
      a_friend_id_required_to: 'От друга требуется:',
      follow_your_link: 'Перейти по вашей ссылке',
      win_10_games: 'Забрать ежедневный бонус',
      list_of_your_friends: 'Ваши друзья:',
      button_invete: 'Пригласить друга',
      level_2_friends: 'Чтобы пригласить друзей, нужен 2-й уровень',
      how_top_up: 'Как повысить уровень',
      invite_message: '🔥Играй со мной и получи 500 GoCoin на баланс💸'
    },
    game: {
      bank: 'Банк:',
      bet: 'Ставка:',
      user_hand: '',
      btn_fold: 'Фолд',
      bnt_check: 'Чек',
      btn_call: 'Колл',
      btn_bet: 'Бет',
      btn_raise: 'Рейз',
      join_room: 'Сесть за стол',
      all_in: 'ва-банк'
    },
    notification: {
      you_turn: 'Ваш ход!',
      youve_won: 'Ваш выигрыш: ',
      action: 'Ходит',
      the_winning_hand: 'Победила комбинация: ',
      won: ' выиграл:',
      slice_bank: 'разделили банк'
    },
    settings: {
      language: 'Язык',
      vibration: 'Вибрация',
      music: 'Музыка',
      sounds: 'Звуки',
      animation: 'Анимация',
      deleteAccount: 'Удалить аккаунт',
      userAgreement: 'User Agreement',
      settings: 'Настройки'
    },
    menu: {
      play: "Играть",
      rooms: "Комнаты",
      friends: "Друзья",
      profile: "Профиль",
      settings: "Настройки"
    }, 
    profile: {
      balance: 'RedCoin:',
      levels: 'Уровни',
      deposit: 'Пополнить',
      withdraw: 'Вывести',
      transaction_history: 'История транзакций',
      transaction: 'транзакций',
      history: 'История',
      add: 'Пополнение',
      withdraw2: 'Вывод',
      game_history: 'История игр',
      no_history_label: 'Вы пока ещё не сыграли ни одной игры. Начните игру сейчас:',
      all_rooms_label: 'Все комнаты',
      history_item_room_label: 'Блайнд',
      transaction_again_label: 'Повторить',
      operation_details_label: 'Детали операции',
      status: 'Статус',
      status_success: 'Успешно',
      withdraw_fee: 'Комиссия за вывод',
      daily_bonus: 'Ежедневный бонус',
      claim_bonus: 'Забрать бонус',
      box: 'Чтобы получить больше RedCoin, пригласи друзей!',
      friends: 'Пригласить друзей'
    },
    levels: {
      level_1: '1 уровень',
      level_2: '2 уровень',
      level_3: '3 уровень',
      level_4: '4 уровень',
      to_achive: 'Для достижения',
      top_up_by: 'пополнить на',
      withdraw_fee: 'Комиссия за вывод:',
      or_win: 'или выиграть',
      game_10: '10 партий'
    },
    level_up: {
      congr_1: 'Поздравляем с переходом на уровень',
      new_diz: '! Новое оформление и сниженная комиссия за вывод теперь ваши!',
      view_all_levels: 'Смотреть все уровни'
    }, 
    deposit_and_withdraw: {
      connect_wallet_label: 'Чтобы пополнить баланс, привяжите свой кошелёк TON',
      link_wwallet: 'Привязать кошелёк TON',
      you_are: 'Вы',
      depositing: 'пополняете',
      withdraw: 'выводите',
      your_ton_wallet: 'Ваш кошелёк TON',
      your: 'Ваш',
      level: 'уровень',
      fee: 'Комиссия(2%)',
      reduce: 'Уменьшить',
      reduce_fee: 'комиссию',
      result: 'Итого: '
    },
    delete_account: {
      warning_main: "Вы уверены, что хотите удалить свой аккаунт?",
      warning_ful: 'Все ваши данные, включая прогресс в игре, достижения и покупки, будут безвозвратно удалены.',
      warning_full: 'Это действие нельзя отменить',
      delete_button: 'Удалить акканут',
      cancel_button: 'Отмена'
    },
    chips_popup: {
      go_coins: 'RedCoins',
      chose_go_coins: 'Выберите кол-во RedCoins для игры',
      balance: 'Ваш баланс:',
      minimun_bet: 'Минимальная сумма:',
      waiting: 'Ожидание: ',
      waiting_time: ' сек',
      exit: 'Выйти',
      start_game: 'Начать игру',
      continue: 'Продолжить',
      add_balance: 'Пригласить друзей'
    },
    daily_bonus_pop: {
      claim: 'Забери свой ежедневный бонус',
      claim_first: 'Забери свой первый бонус',
      claim_btn: 'Забрать бонус'
    }
  }
};

const i18n = createI18n({
  locale: savedLanguage,  
  fallbackLocale: 'en',   
  messages,
});

export default i18n;
