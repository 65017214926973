<script setup lang="ts">

import store from "@/store";
import router from "@/router";
import {onUnmounted, ref, nextTick, defineEmits} from "vue";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";

const { t } = useI18n();

const userService = new UserService;

const last_bonus_at = ref(store.getters.USER.last_bonus_at);

const closeModal = () => {
  // store.commit('SET_FOCUSED', false);
  // store.commit('SET_OPEN_MODAL', "");
  emit('close');
}

onUnmounted(() => {
  triggerHapticFeedback('light');
})

const emit = defineEmits(['close']);

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
      last_bonus_at.value = store.getters.USER.last_bonus_at;
}

function getBonus(){
  if (isMoreThan24HoursAgo(last_bonus_at.value)){
    triggerHapticFeedback('soft');
    userService.getBonus(10000);
    nextTick(()=>{
      updateUser();
    })
    emit('close');
  }
}

// // Функция для вычисления оставшегося времени до получения бонуса
// function getRemainingTime(lastBonusAt: string) {
//   const targetDate = new Date(lastBonusAt);
//   const currentDate = new Date();
//   const diffInMilliseconds = 24 * 60 * 60 * 1000 - (currentDate.getTime() - targetDate.getTime());

//   const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
//   const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

//   return { hours, minutes };
// }

function isMoreThan24HoursAgo(dateString: string): boolean {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - targetDate.getTime();
  const hours24InMilliseconds = 24 * 60 * 60 * 1000;

  return diffInMilliseconds > hours24InMilliseconds;
}

</script>

<template>
  <div class="daily-bonus">
    <div class="daily-bonus-wrapper">
      <div class="daily-bonus-wrapper-up">
        <img src="@/assets/tools/close-modal.svg" @click="closeModal()">
      </div>
      <div class="daily-bonus-wrapper-center">
        <div class="daily-bonus-wrapper-cenetr-up">
          <p>{{ t(`daily_bonus_pop.claim`) }}</p>
        </div>
        <div class="daily-bonus-wrapper-center-dwn">
          <p>+10 000</p>
          <img src="@/assets/logo/money_logo.svg" class="red-coin">
          <p class="red-coin-ten-k">RedCoin</p>
        </div>
      </div>
      <div class="daily-bonus-wrapper-dwn" @click="getBonus()">
        {{ t(`daily_bonus_pop.claim_btn`) }}
      </div>
      <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
    </div>
  </div>
</template>

<style scoped>

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.daily-bonus {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0,0,0,0.7);
}

.daily-bonus-wrapper {
  position: relative;
  background-color: #15151E;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  padding-top: 28px;
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 57px;
}

.daily-bonus-wrapper-up {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: right;
  z-index: 1001;
}

.daily-bonus-wrapper-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
}

.daily-bonus-wrapper-cenetr-up {
  font-size: 18px;
}

.daily-bonus-wrapper-center-dwn {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  gap: 3.95vw;
  margin-top: 27px;
}

.daily-bonus-wrapper-dwn {
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  background-color: #5C4E73;
  border-radius: var(--border-mixed);
  z-index: 1001;
}

.red-coin-ten-k {
  font-weight: 500;
}

</style>