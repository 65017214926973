<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import {defineProps} from "vue"

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  animation?: boolean
}>();

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div :class="{ 'header-wrapper': true, 'header-animation': animation}">
    <div class="header-wrapper__user-info" @click="triggerHapticFeedback('light'); router.push('/profile')">
      <img class="header-wrapper__user-image no-image-animation" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
      <div class="header-mini-username">{{ userData.user.username ?? "noname" }}</div>
      <img class="header-wrapper__user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
    </div>
    <div class="header-wrapper__balance">
      <div class="header-balance" @click="triggerHapticFeedback('light'); router.push('/profile')">
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
        <p class="header-balance-sum">{{ formatNumber(store.getters.USER.balance) }}</p>
        <!-- <img src="@/assets/tools/plus.svg" class="no-image-animation"> -->
      </div>
    </div>
  </div>
</template>

<style scoped>

.header-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background-color: #15151D;
  padding: 7px 20px;
  border-bottom-left-radius: var(--border-mixed);
  border-bottom-right-radius: var(--border-mixed);
}

.header-animation {
  animation: header-animation .2s ease-out;
}

@keyframes header-animation {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}

.header-wrapper__user-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 20px;
}

.header-wrapper__user-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.header-wrapper__balance {
  padding: 11px 21px;
  border-radius: var(--border-mixed);
  background-color: #2A2B37;
}

.header-balance-image {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  height: 15px;
  width: 15px;
  margin-right: 5px;
  transition: 0.2s ease color;
}

.header-balance {
  display: flex;
  align-items: center;
}

.header-balance-sum {
  font-weight: 700;
}

.header-wrapper__user-status {
  margin-left: 11px;
}

.header-mini-username {
  max-width: 11ch;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>