import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Echo from "laravel-echo";
import i18n from './i18n';

(window as any).Pusher = require('pusher-js');

(window as any).Echo = new Echo({
    broadcaster: 'pusher',
    key: '7974671eccaad3687b14',
    cluster: 'eu',
    encrypted: true,
});

createApp(App).use(i18n).use(store).use(router).mount('#app')

