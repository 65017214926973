<script setup lang="ts">
import store from "@/store";
import GameCard from "@/models/gameCard";
import Game from "@/models/game";
import Player from "@/models/player";
import { onMounted, watch, defineEmits, inject } from "vue";
import { useI18n } from 'vue-i18n';

const playSound = inject<() => void>("playSound");

const playFlopSound = inject<() => void>("playFlopSound");

const { t } = useI18n();

// eslint-disable-next-line no-undef
const props = defineProps<{
  firstFlopCard: GameCard,
  secondFlopCard: GameCard,
  thirdFlopCard: GameCard,
  ternCard: GameCard,
  riverCard: GameCard,
  game: Game,
  player: Player,
  players: Player[],
  playersCount: number
}>();

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

const flipCard = async (elementId: string, card: GameCard) => {
  const element = document.getElementById(elementId);
  if (!element) return;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const imageUrl = require(`@/assets/cards/open/${store.getters.USER.vip_status}/${card.card_rank.toLowerCase()}_${card.card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`);

  await preloadImage(imageUrl);

  element.style.animation = 'moveCard 0.7s ease forwards, flipCard 0.5s ease forwards';
  if (card == props.secondFlopCard) {
    element.style.animationDelay = '0.2s, 1.2s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1450);
  } else if (card == props.thirdFlopCard) {
    element.style.animationDelay = '0.4s, 1.4s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1650);
  } else {
    element.style.animationDelay = '0s, 1s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1250);
  }
};

// Позиция стола

const emit = defineEmits<{
  (event: 'tableCardsPosition', data: { top: number; left: number; centerX: number; centerY: number }): void;
}>();



function setPositionPreflopCard() {
  const tableCardsElement = document.getElementById('tablecards');
  
  if (tableCardsElement) {
    const rect = tableCardsElement.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    // TypeScript сам поймет тип коллекции элементов
    const preflopCards = document.getElementsByClassName('preflop-card');

    for (let i = 0; i < preflopCards.length; i++) {
      const element = preflopCards[i] as HTMLElement; // Приведение к типу HTMLElement
      element.style.left = `${centerX - element.offsetWidth / 2}px`;
      element.style.top = `${centerY - element.offsetHeight / 2}px`;
    }
  }
}





// Позиция стола


onMounted(() => {
  if (props.firstFlopCard.is_opened) flipCard('flop-1', props.firstFlopCard);
  if (props.secondFlopCard.is_opened) flipCard('flop-2', props.secondFlopCard);
  if (props.thirdFlopCard.is_opened) flipCard('flop-3', props.thirdFlopCard);
  if (props.ternCard.is_opened) flipCard('tern', props.ternCard);
  if (props.riverCard.is_opened) flipCard('river', props.riverCard);
  setPositionPreflopCard();
});

watch(() => props.firstFlopCard.is_opened, (newValue) => {
  if (newValue) {
  flipCard('flop-1', props.firstFlopCard);
  if (playSound) {
    playSound(); 
  } 
  } 
}, { deep: true });

watch(() => props.secondFlopCard.is_opened, (newValue) => {
  if (newValue) {
    flipCard('flop-2', props.secondFlopCard);
  if (playSound) {
    playSound(); 
  }
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
}, { deep: true });

watch(() => props.thirdFlopCard.is_opened, (newValue) => {
  if (newValue) {
    flipCard('flop-3', props.thirdFlopCard);
    if (playSound) {
    playSound(); 
  }  
  } 
}, { deep: true });

watch(() => props.ternCard.is_opened, (newValue) => {
  if (newValue) {
    flipCard('tern', props.ternCard);
    if (playSound) {
    playSound(); 
  } 
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
}, { deep: true });

watch(() => props.riverCard.is_opened, (newValue) => {
  if (newValue) {
    flipCard('river', props.riverCard);
    if (playSound) {
    playSound(); 
  }  
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
}, { deep: true });

// watch(() => props.player, (newValue) => {
//   console.log(props.player?.current_round_bet)
// })


</script>

<template>
  <div class="table-cards" id="tablecards">
    <img src="@/assets/logo/logo-background-table.svg" class="logo-background">
    <div>
      <img :class="['preflop-card']" id="preflop-1" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-2" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-3" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-4" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-5" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-6" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-7" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-8" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-9" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-10" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-11" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-12" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-13" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-14" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-15" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-16" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-base" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
    </div>
    <div class="cards">
      <div>
        <img :class="['card']" id="flop-1"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
        <img :class="['card']" id="flop-2"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
             >
        <img :class="['card']" id="flop-3"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
             >
      </div>
      <div>
        <img :class="['card']" id="tern"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
             >
        <img :class="['card']" id="river"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
             >
      </div>
    </div>
    <div class="bank-bet">
      <div class="bank">
        <p class="bank-label">{{ t(`game.bank`) }}</p>
        <p class="bank-sum">{{ game?.bank ?? 0 }}</p>
      </div>
      <div class="bet">
        <p class="bet-label">{{ t(`game.bet`) }}</p>
        <p class="bet-sum">{{ Math.max(...players.map(x => x.current_round_bet)) ?? 0 }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bank-label {
  text-align: left;
  color: #B698EF;
  font-size: 1.95vh;
  margin-bottom: 8px;
}

.bet-label {
  text-align: left;
  color: #B698EF;
  font-size: 1.95vh;
  margin-bottom: 8px;
  margin-top: 15px;
}

.bank-sum {
  text-align: left;
  font-size: 3vh;
  font-weight: 500;
}

.bank{
  margin-right: 12px;
}

.bet-sum {
  text-align: left;
  font-size: 3vh;
  font-weight: 500;
}
.table-cards {
  display: flex;
  position: relative;
  margin-top: -100px;
  z-index: 2;
  justify-content: flex-end;
  width: 133%;
  box-sizing: border-box;
  background: #423757;
  border: 3px solid #5C4E73;
  box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  padding: 13px 10px;
  align-items: center;
  overflow: visible;
}

.cards {
  text-align: right;
  margin-right: 11px;
  margin-left: 2%;
}

.card {
  opacity: 0;
  margin: 3px;
  height: 10vh;
  position: relative;
  animation: none;
  visibility: visible;
}

.logo-background {
  position: absolute;
  z-index: -1;
  height: 100%;
  
  left: 5%;
}

.stack {
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s ease all;
}

.stack-card {
  height: 10vh;
  width: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preflop-card{
  opacity: 0;
  position: absolute;
  height: 25vw;
  transition: left .3s ease, top .3s ease; 
}

#preflop-1, #preflop-2, #preflop-3, #preflop-4, #preflop-5, #preflop-6, #preflop-7, #preflop-8,
#preflop-9, #preflop-10, #preflop-11, #preflop-12, #preflop-13, #preflop-14, #preflop-15, #preflop-16 {
  z-index: 100;
} 

#preflop-base {
  z-index: 90;
}

</style>