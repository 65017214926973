type Language = {
    name: string,
    symbol: string,
}

const languages: Language[] = [
    {
        name: 'Русский',
        symbol: 'ru'
    },
    {
        name: 'English',
        symbol: 'en'
    }
];

export default languages;