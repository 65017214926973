<script setup lang="ts">

// eslint-disable-next-line no-undef
import {onMounted, onUnmounted, ref} from "vue";
import Transaction from "@/models/transaction";
import router from "@/router";
import {useRoute} from "vue-router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {formatLocalDate} from "@/tools/tools";
import store from "@/store";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const transaction = ref<Transaction | null>(null);

let id = 0;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile/transactions')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  const route = useRoute();
  if (route.query.id) {
    id = parseInt(route.query.id);
  }
  const userService = new UserService();
  userService.transaction(id)
      .then(response => response.json())
      .then((data: Transaction) => {
        transaction.value = data;
      })
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const repeatTransaction = (transaction: Transaction) => {
  window.scrollTo(0, 0);
  router.push(`/profile/${transaction.type}`)
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="transaction-wrapper">
    <div class="transaction-labels-wrapper">
      <p class="transaction-label">{{ t(`profile.history`) }}&nbsp;{{ t(`profile.транзакций`) }}</p>
      <div class="transaction-hr">
      </div>
      <img src="@/assets/logo/logo.svg" class="no-image-animation">
    </div>
    <div class="transaction" v-if="transaction">
      <div class="transaction-type-wrapper">
        <img src="@/assets/wallet/ton-transactions.svg" class="transaction-ton">
        <p class="transaction-type">
          {{ transaction.type === 'top-up' ? t('profile.add') : t('profile.withdraw2') }}
        </p>
      </div>
      <div class="transaction-sum-wrapper">
        <div>
          <p class="transaction-sum">{{ transaction.type === 'top-up' ? '+' : '-' }}{{ transaction.ton_sum }}</p>
        </div>
        <div>
          <p class="transaction-currency">TON</p>
        </div>
      </div>
      <div class="transaction-date">
        {{ formatLocalDate(transaction.updated_at, true).split(' ')[0] }} в {{formatLocalDate(transaction.updated_at, true).split(' ')[1] }}
      </div>
      <div class="transaction-again" @click="triggerHapticFeedback('light'); repeatTransaction(transaction)">
        <p class="transaction-again-label">{{ t(`profile.transaction_again_label`) }}</p>
      </div>
      <p class="operation-details-label">{{ t(`profile.operation_details_label`) }}</p>
      <div class="operation-details">
        <p class="status-label">{{ t(`profile.status`) }}</p>
        <p class="status">{{ t(`profile.status_success`) }}</p>
        <div class="operation-details-hr"/>
        <p class="commission-label">{{ t(`profile.withdraw_fee`) }}</p>
        <p class="commission">0.06&nbsp;TON</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.transaction-wrapper {
  margin-top: 15px;
  margin-bottom: 123px;
}

.transaction-labels-wrapper {
  display: flex;
  align-items: center;
}

.transaction-label {
  font-size: 18px;
  font-weight: 500;
}

.transaction-hr {
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  width: 100%;
  height: 1px;
}

.transaction {
  margin-top: 33px;
}

.transaction-type-wrapper {
  text-align: left;
  display: flex;
  align-items: center;
}

.transaction-ton {
  margin-right: 7px;
}

.transaction-type {
  font-weight: 500;
  font-size: 18px;
}

.transaction-sum-wrapper {
  display: flex;
  align-items: end;
  margin-top: 25px;
  justify-content: left;
}

.transaction-sum {
  line-height: 1;
  margin-right: 5px;
  font-family: Inter, sans-serif;
  font-size: 60px;
}

.transaction-currency {
  line-height: 1;
  font-family: Inter, sans-serif;
  font-size: 40px;
  color: #A4A4A4;
  margin-bottom: 2px;
}

.transaction-date {
  text-align: left;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  color: #B9B9B9;
}

.transaction-again {
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding-left: 25px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 35px;
}

.transaction-again-label {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}

.operation-details-label {
  font-size: 16px;
  font-weight: 500;
  color: #B9B9B9;
  text-align: left;
  margin-top: 25px;
}

.operation-details {
  background-color: #45395B;
  padding: 15px 32px 15px 20px;
  margin-top: 20px;
  border-radius: var(--border-mixed);
}

.status-label {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
}

.status {
  margin-top: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.operation-details-hr {
  background-color: #A4A4A4;
  height: 1px;
  width: 100%;
  margin-top: 5px;
}

.commission {
  margin-top: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.commission-label {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
  margin-top: 15px;
}
</style>