<script setup lang="ts">

import TransactionsList from "@/components/lists/transactions/TransactionsList.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="transactions_wrapper">
    <div class="transaction-labels-wrapper">
      <p class="transaction-label">{{ t(`profile.history`) }}&nbsp;{{ t(`profile.transaction`) }}</p>
      <div class="transaction-hr">
      </div>
      <img src="@/assets/logo/logo.svg" class="no-image-animation">
    </div>
    <div class="transaction-list-wrapper">
      <transactions-list/>
    </div>
  </div>
</template>

<style scoped>
.transactions_wrapper {
  margin-top: 15px;
  margin-bottom: 123px;
}

.transaction-labels-wrapper {
  display: flex;
  align-items: center;
}

.transaction-label {
  font-size: 18px;
  font-weight: 500;
}

.transaction-hr {
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  height: 1px;
}

.transaction-list-wrapper {
  height: 100%;
}
</style>