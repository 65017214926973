<script setup lang="ts">

import Transaction from "@/models/transaction";
import router from "@/router";
import {formatLocalDate} from "@/tools/tools";
import { useI18n } from 'vue-i18n';
import store from "@/store";
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps<{
  transaction: Transaction
}>();

const pushToTransaction = (id: number) => {
  window.scrollTo(0, 0);
  router.push(`/profile/transaction?id=${id}`)
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="transaction" @click="triggerHapticFeedback('light'); pushToTransaction(transaction.id)">
    <div class="transaction-sum-wrapper">
      <img class="ton-transaction-logo" src="@/assets/wallet/ton-transactions.svg">
      <p :class="{ 'transaction-sum': true, 'transaction-top-up': transaction.type == 'top-up', 'transaction-withdraw': transaction.type == 'withdraw' }">
        {{ transaction.type == "top-up" ? "+" : "-" }}{{ transaction.ton_sum }}
      </p>
    </div>
    <div class="transaction-date-wrapper">
      <p class="transaction-type-label">{{ transaction.type == 'top-up' ? t('profile.add') : t('profile.withdraw2') }}</p>
      <p class="transaction-date">{{ formatLocalDate(transaction.updated_at) }}</p>
    </div>
    <div class="transaction-arrow-wrapper">
      <img src="@/assets/tools/arrow.svg">
    </div>
  </div>
</template>

<style scoped>
.transaction {
  background-color: #5C4E73;
  border-radius: var(--border-mixed);
  padding: 15px 26px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

.ton-transaction-logo {
  margin-right: 10px;
}

.transaction-sum-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;;
}

.transaction-top-up {
  color: #31B545;
}

.transaction-sum {
  font-size: 18px;
  font-weight: 500;
}

.transaction-withdraw {
  color: #FF0C17;
}

.transaction-type-label {
  font-weight: 500;
  font-size: 14px;
}

.transaction-date {
  font-size: 17px;
  font-weight: 500;
  margin-top: 2px;
}

.transaction-date-wrapper {
  text-align: center;
}

.transaction-arrow-wrapper {
  text-align: right;
  margin-left: 40px;
}
</style>