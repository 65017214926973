<script setup lang="ts">
import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import Room from "@/models/room";
import { computed, ref, watch, defineProps, defineEmits, onMounted } from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();


const props = defineProps<{
  player: Player,
  players: Player[],
  game: Game,
  room: Room,
  value: number,
  min: number,
}>();

const emit = defineEmits(['updateSliderValue']);


const sliderValue = ref(props.min);


watch(() => props.min, (newMin) => {
  if (sliderValue.value < newMin && sliderValue.value > props.player.game_deposit) {
    sliderValue.value = newMin/2;  
  } else sliderValue.value = newMin;
});



watch(() => props.value, (newVal) => {
  sliderValue.value = Math.max(newVal, props.min);  
});


const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');
  const newValue = Math.max(Number(target.value), props.min); 
  console.log('New Value:', newValue)
  if (newValue >= props.player.game_deposit - props.player.current_round_bet) {
    console.log('Зашёл в if')
    sliderValue.value = props.player.game_deposit + props.player.current_round_bet;
    
    console.log('sliderValue.value:', sliderValue.value)
    emit('updateSliderValue', sliderValue.value)
  }
  else 
  {sliderValue.value = newValue;  
  emit('updateSliderValue', newValue); 
  }  
}


watch(sliderValue, (newVal) => {
  emit('updateSliderValue', Number(newVal)); 
});

onMounted(() => {
  emit('updateSliderValue', sliderValue.value); 
})

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});


const getOpacityBetSlider = computed(() => {
  return props.game && props.game.current_player_id === props.player.id ? '1' : '.4';
});


const isSliderDisabled = computed(() => {
  return getOpacityBetSlider.value === '.4';
});


const getMaxBet = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getMaxRaise = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getSliderValue = computed(() => {
  if (sliderValue.value == props.player.game_deposit + props.player.current_round_bet){
    return t('game.all_in');
  }
  if (sliderValue.value == getMaxBet.value) {
    return t('game.all_in');
  }
  else {
    if (sliderValue.value == getMaxRaise.value) {
      return t('game.all_in');
    }
  }
  return sliderValue.value;
});


watch(getMaxBet, () => {
  if (sliderValue.value > getMaxBet.value) {
    sliderValue.value = getMaxBet.value;
  }
});


watch(getMaxRaise, () => {
  if (sliderValue.value > getMaxRaise.value) {
    sliderValue.value = getMaxRaise.value;
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>


<template>
  <div>
    <div class="bet-slider-wrapper">
      <div class="bet-slider">
        <p class="bet-sum-draft">{{ getSliderValue }}</p>
        <input
            v-if="player.current_round_bet >= Math.max(...players.map(x => x.current_round_bet))"
            v-model="sliderValue"
            class="bet-slider-input"
            :min="min"
            :max="getMaxBet"
            type="range"
            :disabled="isSliderDisabled"
            @input="handleInput"
        />
        <input
            v-else
            v-model="sliderValue"
            class="bet-slider-input"
            :min="min"
            :max="getMaxRaise"
            type="range"
            :disabled="isSliderDisabled"
            @input="handleInput"
        />
        <p class="bet-sum-draft">{{ min }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
input[type="range"] {
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
  writing-mode: vertical-rl !important;
  direction: rtl !important;
  height: 26vh;
  background: transparent;
  width: 50%;
  opacity: v-bind('getOpacityBetSlider');
  height: 357px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 10vh;
  width: auto;
  aspect-ratio: 19 / 60;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-right: -1.3vh;
  background: v-bind('getColorByStatus');
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  border-radius: 2px;
  width: 3px;
  background: v-bind('getColorByStatus');
}

.bet-sum-draft {
  font-size: 1.95vh;
  text-wrap: nowrap;
}
.bet-slider {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2vh;
  font-size: 4.2vh;
  justify-content: center;
}

.wrapper {
}

.bet-slider-wrapper {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #00000060;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -227px; 
  padding-top: 18px;
  padding-bottom: 18px;
  width: 17.2093vw;
  padding-right: 4px;
}

.new-message {
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  width: 95%;
  background: #423757;
  box-shadow: -4px -7px 40px rgba(0, 0, 0, 0.6), 4px 7px 40px rgba(0, 0, 0, 0.6);
  border-radius: 0 20px 20px 0px;
  z-index: 1;
}

.chat-wrapper {
  padding: 3.72vh;
  position: relative;
}
</style>