<script setup lang="ts">

import {defineProps, watch, defineEmits, ref, computed, onUnmounted, onMounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void
}>()

const emit = defineEmits(['close']); 

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const min = props.room.big_blind_bet * 100;
const max = props.room.big_blind_bet * 100*5;

const tg = window.Telegram?.WebApp;

const inputValue = ref<number>(min);

const timer = ref<number>(15);

const roomService = new RoomService();


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');

  const newValue = Math.max(Number(target.value), min);

  inputValue.value = newValue;

  
};

function startTheGame(){
  if (inputValue.value > store.getters.USER.balance) return;
  if (inputValue.value <= max) {
    roomService.setDeposit(inputValue.value, props.player.user_id)
    .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }});
  }
  props.joinRoom();
}

onMounted(() =>{
  const slider = document.getElementById('slider') as HTMLInputElement;
  slider.step = (max / 100).toString();
  inputValue.value = min;
  timer.value = 15;
  countTimer();
})

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close'); // Прокидываем эмит в родитеский компонент после завершения таймера
      clearInterval(interval); // Останавливаем таймер
    }
  }, 1000);
}

</script>


<template>
  <div class="chips-popup">
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div class="chips-for-game">
              <p>{{ t(`chips_popup.go_coins`) }}</p>
            </div>
            <div class="close" @click="emit('close')">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div class="chips-count-for-game">
            <p>{{ t(`chips_popup.chose_go_coins`) }}</p>
          </div>
        </div>
        <div class="chips-center">
          <div class="chips-center-up">
            <div class="chips-center-lft">
              <div class="chips-center-lft-up">
                <p>{{ t(`chips_popup.balance`) }}</p>
              </div>
              <div class="chips-center-lft-dwn">
                <p>{{ formatNumberByZiro(store.getters.USER.balance)  }}</p>
                <img class="chips-min" src="@/assets/logo/money_logo.svg">
              </div>
          </div>
          <div class="chips-center-rgt">
            <div class="chips-center-rgt-up">
              <p>{{ t(`chips_popup.minimun_bet`) }}</p>
            </div>
            <div class="chips-center-rgt-dwn">
              <p>{{ formatNumberByZiro(min) }}</p>
              <img class="chips-min" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
          </div>
          <div class="chips-center-dwn">
            <p :style="{ color: store.getters.USER.balance < inputValue   ? 'red' : 'white' }">{{ formatNumberByZiro(inputValue) }}</p>
            <img class="chips" src="@/assets/logo/money_logo.svg">
          </div>
        </div>
        <div class="chips-down">
          <div class="chips-slider">
            <input id="slider"
            v-model="inputValue"
            :min=min
            :max=max
            type="range"
            @input="handleInput"
            />
          </div>
          <div class="chips-count-timer">
            <p>{{ t(`chips_popup.waiting`) + timer + t(`chips_popup.waiting_time`) }}</p>
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
      <div class="chips-wrapper-btns">
        <div class="chips-bt1" @click="router.push('/rooms')">
          {{ t(`chips_popup.exit`) }}
        </div>
        <div v-if="inputValue > store.getters.USER.balance" class="chips-bt2" @click="triggerHapticFeedback('light'); router.push('/friends')">
          {{ t(`chips_popup.add_balance`) }}
        </div>
        <div v-else class="chips-bt2" @click="triggerHapticFeedback('light'); startTheGame()">
          {{ t(`chips_popup.start_game`) }}
          <!-- {{ t(`chips_popup.continue`) }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.chips-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: #2A2B3797;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 353px;
}

.chips-center-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chips-center-lft {
  display: flex; 
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.chips-center-lft-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
}

.chips-center-lft-dwn {
  font-size: 15px;
  text-align: start;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-rgt {
  display: flex; 
  justify-content: end;
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

.chips-center-rgt-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  text-align: end;
}

.chips-center-rgt-dwn {
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-dwn {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 25px;
}

.chips {
  width: 6.98vw;
  max-width: 30px;
}

.chips-min {
  width: 5.12vw;
  max-width: 22px;
}

.chips-down {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  flex-direction: column;
}

.chips-slider {
  width: 77.67vw;
  max-width: 334px;
  position: relative;
  z-index: 1000;
}

input[type="range"] {
  width: 77.67vw;
  max-width: 334px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 19px;
  width: 60px;
  margin-top: -1.2vh;
  border-radius: 50px;
  background: v-bind('getColorByStatus');
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: v-bind('getColorByStatus');
}

.chips-count-timer {
  margin-top: 20px;
  font-size: 15px;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
}

</style>