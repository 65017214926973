<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import { computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n(); 

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = defineProps({
  on: Function,
  off: Function,
  click: Function,
  type: String,
  label: String,
});

const defaultState = computed(() => {
  switch (props.label) {
    case 'settings.vibration':
      return store.getters.isVibrationOn;
    case 'settings.music':
      return store.getters.isMusicOn;
    case 'settings.sounds':
      return store.getters.isSoundOn;
    default:
      return false;
  }
});

const handleClick = (newState: boolean) => {
  if (props.label === 'settings.vibration') {
    store.commit(newState ? 'ENABLE_VIBRATION' : 'DISABLE_VIBRATION');
  } else if (props.label === 'settings.music') {
    store.commit(newState ? 'ENABLE_MUSIC' : 'DISABLE_MUSIC');
  } else if (props.label === 'settings.sounds') {
    store.commit(newState ? 'ENABLE_SOUND' : 'DISABLE_SOUND');
  } 

  if (props.on && newState) props.on();
  if (props.off && !newState) props.off();
};

const handleClickItem = () => {
  if (props.type === 'link' && props.click) {
    triggerHapticFeedback('light');
    props.click();
  }
};
</script>

<template>
  <div class="settings-item" v-if="type === 'switch'">
    <p class="settings-label">{{ t(label) }}</p>
    <div 
      :class="{ 'setting-switcher': true, 'setting-switcher__on': defaultState, 'setting-switcher__off': !defaultState }" 
    >
      <div 
        :class="{ 'setting-switcher-state': true, 'setting-switcher-state__on': defaultState, 'setting-switcher-state__off': !defaultState }"
        @click=" triggerHapticFeedback('light'); handleClick(!defaultState)"
      ></div>
    </div>
  </div>
  <div v-else class="settings-item" @click="handleClickItem">
    <p class="settings-label">{{ t(label) }}</p>
    <div :class="{ 'setting-switcher-mock': true }">
      <div :class="{ 'setting-switcher-state-mock': true }"></div>
    </div>
    <img src="@/assets/tools/arrow.svg">
  </div>
</template>

<style scoped>
.settings-item {
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding: 17px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-switcher {
  display: flex;
  padding: 2px;
  border-radius: 17px;
  width: 55px;
  cursor: pointer;
}

.setting-switcher-mock {
  padding: 2px;
  border-radius: 17px;
  width: 55px;
  background-color: #5C4E73;
}

.setting-switcher-state-mock {
  border-radius: 17px;
  width: 31px;
  height: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #5C4E73;
}

.setting-switcher-state {
  border-radius: 17px;
  width: 31px;
  height: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.setting-switcher-state__off {
  text-align: left;
  justify-content: left;
  background-color: #5C4E73;
}

.setting-switcher-state__on {
  text-align: right;
  justify-content: right;
  background-color: #B093E6;
}

.setting-switcher__on {
  text-align: right;
  justify-content: right;
  background-color: #7861A5;
}

.setting-switcher__off {
  text-align: left;
  justify-content: left;
  background-color: #282934;
}

.settings-label {
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}
</style>
