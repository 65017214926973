<template>
  <div class="balance-action-input">
    <p class="balance-action-label">{{ t(`deposit_and_withdraw.you_are`) }} {{ action === 'top-up' ? t('deposit_and_withdraw.depositing') : t('deposit_and_withdraw.withdraw')}}:</p>
    <div v-if="action === 'top-up'" class="balance-input-wrapper">
      <input pattern="\\d*" inputmode="numeric" @input="onInput" min="0" max="10000" :style="{ width: inputData.toString().length + 'ch', color: inputData.toString() === '0' ? 'red' : 'white' }" class="balance-input" type="number" v-model="inputData" ref="inputRef">
      <p class="balance-input-ton">TON</p>
    </div>
    <div v-else class="balance-input-wrapper">
      <input pattern="\\d*" inputmode="numeric" @input="onInput" min="0" max="100000" :style="{ width: inputData.toString().length + 'ch', color: parseInt(inputData) < 1000 ? 'red' : 'white' }" class="balance-input" type="number" v-model="inputData" ref="inputRef">
      <img class="balance-game-logo-big" src="@/assets/logo/money_logo.svg">
    </div>
    <div v-if="action==='top-up'">
      <div class="container">
        <div class="text">
          <div class="balance-wrapper-comission">
            <p class="balance-level">{{ t(`deposit_and_withdraw.fee`) }}:&nbsp;</p>
            <p class="balance-game-label" id="comission">
              {{ comission + ' TON' }}
            </p>
          </div>
        </div>
      </div>  
    </div>
    <div v-else>
      <div class="container">
        <div class="text">
          <div class="balance-wrapper">
            <p class="balance-level">{{ t(`deposit_and_withdraw.your`) }}&nbsp;{{ t(`deposit_and_withdraw.level`) }}&nbsp;</p>
            <p class="balance-game-label" id="level">
              1
            </p>
          </div>
          <div class="balance-wrapper-comission">
            <p class="balance-level">{{ t(`deposit_and_withdraw.fee`) }}:&nbsp;</p>
            <p class="balance-game-label" id="comission">
              {{ comission + ' TON' }}
            </p>
          </div>
          <div class="balance-wrapper-comission">
            <p class="balance-level">{{t(`deposit_and_withdraw.result`)}}&nbsp;</p>
            <p class="balance-game-label" id="comission">
              {{result + ' TON'}}
            </p>
          </div>
        </div>
      </div>
      <div class="reduce-commission" @click="triggerHapticFeedback('light'); router.push('/levels')">
          <p>{{ t(`deposit_and_withdraw.reduce`) }}&nbsp;{{ t(`deposit_and_withdraw.reduce_fee`) }}</p>
        </div>
    </div>
    <div v-if="action === 'top-up'" class="balance-game-wrapper">
      <p class="balance-game-label">{{ t(`deposit_and_withdraw.result`) }}&nbsp;{{ (parseInt(inputData) * 1000) - (parseInt(inputData) * 1000 * 0.02) }}</p>
      <img class="balance-game-logo" src="@/assets/logo/money_logo.svg">
    </div>
    <div v-if="action === 'top-up'" class="chips-wrapper" :style="{ 'max-width': '100vw' }">
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('10')">
        <p class="chips-data">10 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('20')">
        <p class="chips-data">20 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft');setInputData('30')">
        <p class="chips-data">30 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('40')">
        <p class="chips-data">40 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('50')">
        <p class="chips-data">50 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('100')">
        <p class="chips-data">100 TON</p>
      </div>
    </div>
    <div class="wallet-wrapper">
      <p class="wallet-label">{{ t(`deposit_and_withdraw.your_ton_wallet`) }}</p>
      <wallet-component/>
    </div>
  </div>
</template>


<script setup lang="ts">
import {onMounted, onUnmounted, ref, defineProps} from "vue";
import WalletComponent from "@/components/wallet/WalletComponent.vue";
import { useStore } from "vuex";
import router from "@/router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useStore();

const currentLevel = getUserLevel(store.getters.USER.vip_status);

function getUserLevel(vipStatus: string): number {
  switch (vipStatus) {
    case 'usual':
      return 2; 
    case 'silver':
      return 3; 
    case 'gold':
      return 4; 
    default:
      return 1; 
  }
}

store.commit('SET_OLD_LEVEL', currentLevel);

const tg = (window as any).Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = defineProps<{
  action: "top-up" | "withdraw"
}>();


let inputData = ref("1");
if (props.action==="top-up"){
  inputData = ref("1");
}
else inputData = ref("1000");

let comission = ref();

let result = ref();

let commisionOnWithdraw = 5;

const inputRef = ref<HTMLInputElement | null>(null);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.go(-1);
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (props.action==="top-up"){
    if (inputData.value === '0') {
      return;
    }
  }
  else if (parseInt(inputData.value) < 1000){
    return;
  }
  
  postEvent('web_app_setup_main_button', {
    is_progress_visible: true,
  })
  doBalanceAction();
}

function onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, '');
  if (value === '') {
    value = '0'
  }
  else if (value.charAt(0) ===  '0') {
    value = value.substring(1)
  }

  inputData.value = value;
  if (props.action === 'top-up'){
    comission.value = Number(inputData.value)*2/100;
    result.value = Number(inputData.value)- comission.value;
  } else {
    comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
    result.value = Number(inputData.value) / 1000 - comission.value;
  }
  

}

function setInputData(data: string) {
  inputData.value = data;
  comission.value = Number(inputData.value)*2/100;
  result.value = Number(inputData.value)- comission.value;
}

onMounted(() => {
  if (!store.getters.USER.address) {
    router.push('/profile/connect-wallet')
  }

  const levelElem = document.getElementById('level');
  
  if (levelElem){
      if (store.getters.USER.vip_status == 'first'){
      levelElem.innerText = '1'
      commisionOnWithdraw = 5;
    }
    else {
      switch (store.getters.USER.vip_status){
        case 'silver':{
          levelElem.innerText = '3'
          commisionOnWithdraw = 3;
          break;
        }
        case 'gold':{
          levelElem.innerText = '4'
          commisionOnWithdraw = 2;
          break;
        }
        default:{
          levelElem.innerText = '2'
          commisionOnWithdraw = 4;
          break;
        }
      }
    }
  }

  if (inputRef.value) {
    if (props.action==="top-up"){
      inputRef.value.value = "1";
      comission.value = Number(inputData.value)*2/100;
      result.value = Number(inputData.value) - comission.value;
    }
    else {
      inputRef.value.value = "1000";
      comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
      result.value = Number(inputData.value) / 1000 - comission.value;
    }

    inputRef.value.focus();
  }

  store.commit('SET_FOCUSED', true)
  postEvent('web_app_setup_back_button', { is_visible: true});
  postEvent('web_app_setup_main_button', {
    is_visible: true,
    is_active: true,
    is_progress_visible: false,
    text: props.action == 'top-up' ? 'Пополнить' : 'Вывести',
    color: '#32A1E6',
    text_color: '#FFFFFF'
  })
  on('back_button_pressed', listener);
  on('main_button_pressed', mainButtonPressed);
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
})


const doBalanceAction = () => {
  const userService = new UserService();
  if (props.action === 'top-up') {
    userService.topUp(result.value)
        .then(response => response.json())
        .then(data => {
          store.commit('SET_USER', data);
        });
  } else {
    userService.withdraw(parseInt(inputData.value)/1000)
        .then(response => response.json())
        .then(data => {
          if (data.balance !== undefined) {
            store.commit('SET_USER', data);
          }
        });
  }
  
  router.push('/profile');
}



</script>

<style scoped>
.balance-action-input {
  text-align: left;
}

.balance-action-label {
  margin-top: 39px;
  font-weight: 500;
  font-size: 18px;
}

.balance-input {
  background: transparent;
  font-size: 60px;
  border: none;
  color: #F3F3F3;
  outline: none;
  margin-top: 2px;
  text-align: left;
}

.balance-input-wrapper {
  display: flex;
  text-align: left;
  align-items: end;
}

.balance-input-ton {
  margin-left: 12px;
  margin-bottom: 5px;
  font-size: 40px;
  text-align: left;
  color: #A4A4A4;
}
.balance-input-ton-min {
  margin-left: 6px;
  font-size: 18px;
  text-align: left;
  color: #A4A4A4;
}

.balance-game-logo {
  width: 20px;
  height: 20px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.balance-game-logo-big {
  width: 34px;
  height: 34px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-bottom: 15px;
  margin-left: 10px;
}

.balance-game-wrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.balance-game-label {
  font-weight: 500;
  font-size: 18px;
}

.wallet-wrapper {
  margin-top: 40px;
}

.wallet-label {
  margin-bottom: 14px;
  text-align: left;
}

.enter {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px 12px;
  background-color: white;
}

.enter-button {
  border-radius: 9px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  background-color: #007AFF;
}

.chips-wrapper {
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  gap: 18px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chips-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.chips {
  background: #5C4E73;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 14px;
}

.chips-data {
  font-size: 16px;
  font-weight: 500;
  margin: 11px 13px;
}

.reduce-commission {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-top: 24px;
}

.container{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text{
  display: flex;
  flex-direction: column;
}

.balance-wrapper{
  display: flex;
  align-items: center;
}

.balance-level{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: left;
  color: #A4A4A4;
}
.balance-wrapper-comission{
  display: flex;
  align-items: center;
  margin-top: 6px;
}


</style>
